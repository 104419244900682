<template>
	<div class="panel panel-default">
		<div class="panel-heading">
			<strong>Results from your import</strong>
		</div>
		<div class="panel-body">
			<p>Added <strong>{{ results.new }}</strong> new people.</p>
			<p>Updated <strong>{{ results.updated }}</strong> existing people.</p>
			<p>No changes to <strong>{{ results.unchanged }}</strong> existing people.</p>
			<p>Skipped <strong>{{ results.skipped }}</strong> rows.</p>
			<p>Skipped <strong>{{ results.invalid }}</strong> rows because of missing or invalid data.</p>
		</div>
	</div>
</template>

<script>
	export default {
		props: [ 'results' ],
	}
</script>
