import $ from '../jquery.js';
import _ from 'lodash';
import DataTablesBase from '../datatables.js';

var Users = new DataTablesBase();

Users.aeTemplate = _.template($('#users-dialog-permissions-ae-template').html());

Users.permissionCount = 0;

Users.bindings = function() {
	$(document).on('shown.bs.modal', '.users-dialog', function() {
		$(this).find('.users-dialog-permission-type').trigger('change');
	});

	$(document).on('change', '.users-dialog-permission-type', function() {
		var val = $(this).val(),
			select = $(this).closest('.users-dialog-permission').find('.users-dialog-permission-type-id');
		//Find the options we have, clone them, and put them in the right place.
		$('#users-permission-select-' + val).find('option').clone().appendTo(select.empty());
		if(select.data('value')) {
			select.val(select.data('value'));
		}
	});

	$(document).on('change', '.users-dialog-permission-type-id', function() {
		var val = $(this).val(),
			row = $(this).closest('.users-dialog-permission'),
			type = row.find('.users-dialog-permission-type').val(),
			aes_row = row.find('.users-dialog-permission-agency-aes'),
			agencyaes;

		aes_row.toggleClass('hide', type !== 'agency');
		if(type === 'agency') {
			agencyaes = window.agencyaes[val];
			aes_row.find('ul').empty();
			_.each(agencyaes, function(val, key) {
				var perm_id = row.data('id'),
					html = $(Users.aeTemplate({
						id: key,
						name: val,
						perm_id: perm_id,
					}));

				html.find('input').attr(
					'id',
					html.find('input').attr('id') + '-' + perm_id + '-' + key);

				html.find('input').attr(
					'aria-labelledby',
					html.find('input').attr('aria-labelledby') + '-' + perm_id + '-' + key);

				html.find('label').attr(
					'id',
					html.find('label').attr('id') + '-' + perm_id + '-' + key);

				html.find('label').attr(
					'for',
					html.find('label').attr('for') + '-' + perm_id + '-' + key);

				aes_row.find('ul').append(html);
			});
		}
	});

	$(document).on('click', '.user-dialog-permission-add', function(e) {
		e.preventDefault();

		Users.permissionCount ++;

		var row = $('.users-dialog-permission-blank').clone();

		row.removeClass('users-dialog-permission-blank').addClass('hide-remove');
		row.appendTo($(this).closest('fieldset'));
		row.data('id', 'n' + Users.permissionCount);

		row.find('input, select').each(function() {
			$(this).attr('name', $(this).attr('name').replace('BLANK', 'n' + Users.permissionCount));
			if($(this).attr('id')) {
				$(this).attr('id', $(this).attr('id').replace('_input', '_input_n' + Users.permissionCount));
			}
		});

		row.find('label').each(function() {
			if($(this).attr('for')) {
				$(this).attr('for', $(this).attr('for').replace('_input', '_input_n' + Users.permissionCount));
			}
		});

		row.find('.users-dialog-permission-type').trigger('change');
		row.find('.users-dialog-permission-type-id').trigger('change');
	});

	$(document).on('click', '.users-dialog-permission-remove', function(e) {
		e.preventDefault();
		$(this).closest('.users-dialog-permission').remove();
	});

	$(document).on('click', '.btn-activity', function(e) {
		e.preventDefault();
		var userId = $(this).closest('tr').data('id');

		window.open(window.config.url.base + 'events/' + userId);
	});
};

export default Users;