import components from '../../components.js';

import peopleVerify from './components/people-verify.vue';
import entry from './components/entry.vue';
import support from './/components/support.vue';
import job from './components/job.vue';
import dateVerified from './components/date-verified.vue';

export default {
	... components,
	
	"people-verify": peopleVerify,
	"verify-entry": entry,
	"verify-support": support,
	"verify-job": job,
	"verify-date-verified": dateVerified,
};
