import _ from 'lodash';

let jQuery = window.jQuery;

//Extend jQuery
jQuery.fn.extend({
	serializeJSON() {
		const data = {};
		const serialize = jQuery(this).serializeArray();

		for (const value of serialize) {
			_.set(data, value.name, value.value);
		}

		return data;
	},

	forceHide() {
		jQuery(this).removeClass('modal-protect-close').modal('hide').addClass('modal-protect-close');
		return jQuery(this);
	},

	focusFirst(filter) {
		var tabbables =  jQuery(this).find(':tabbable');
		if(typeof filter === 'string') {
			tabbables = tabbables.filter(filter);
		}
		return tabbables.first().focus();
	},

	initModal(submit) {
		jQuery(this).appendTo('body');
		jQuery(this).on('hidden.bs.modal.remove', function() {
			(function(that) {
				setTimeout(function() {
					jQuery(that).remove();
				}, 800);
			})(this);
		}).modal();
		if(typeof submit === 'function') {
			jQuery(this).find('form').on('submit', function(e) {
				if(submit.call(this) !== true) {
					e.preventDefault();
				}
			});
		}
		return jQuery(this);
	},

	preventTabOut(event) {
		if(typeof event === 'undefined') {
			return jQuery(this).on('keydown', function(e) {
				jQuery(this).preventTabOut(e);
			});
		}
		// prevent tabbing out of stuff
		// borrowed from jQuery UI
		if(event.keyCode !== jQuery.ui.keyCode.TAB) {
			return;
		}
		var tabbables = jQuery(this).find(":tabbable").filter(':not(.close)'),
			first = tabbables.filter(":first"),
			last  = tabbables.filter(":last");

		if((event.target === last[0] || event.target === jQuery(this)) && !event.shiftKey) {
			first.focus();
			event.preventDefault();
		} else if((event.target === first[0] || event.target === jQuery(this)) && event.shiftKey) {
			last.focus();
			event.preventDefault();
		}
		return jQuery(this);
	},

	pluck(key, attr) {
		return jQuery.map(jQuery(this), function(val) {
			return attr === true ? jQuery(val).attr(key) : jQuery(val).data(key);
		});
	},
});

jQuery.expr[":"].contains = jQuery.expr.createPseudo(function(arg) {
	return function( elem ) {
		return jQuery(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
	};
});