var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list-group-item" }, [
      _c("header", { staticClass: "row row-gutter-sm" }, [
        _c("div", { staticClass: "col col-sm-10" }, [
          _c("div", { staticClass: "row row-gutter-sm" }, [
            _c("div", { staticClass: "col col-sm-5" }, [
              _c("strong", [_vm._v("Employer")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col col-sm-3" }, [
              _c("strong", [_vm._v("Job Type")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col col-sm-2" }, [
              _c("strong", [_vm._v("Hours")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col col-sm-2" }, [
              _c("strong", [_vm._v("Earnings")])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col col-sm-2" }, [
          _c("strong", [_vm._v("Options")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }