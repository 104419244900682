<template>
	<div class="alert alert-success" v-if="!person">
		<p>You have no people left to verify currently.</p>
	</div>

	<verify-entry v-else @complete="next" :person="person" :date="date" :agency="agency" :peopleRemaining="remaining" :key="person.id"></verify-entry>
</template>

<script>
	export default {
		props: [ 'people', 'agency', 'date' ],

		data() {
			return {
				remaining: this.people.length,
				person: this.people.shift(),
			};
		},

		methods: {
			next() {
				this.remaining = this.people.length;
				this.person = this.people.shift();
			}
		},
	}
</script>
