import config from '../config.js';
import $ from '../jquery.js';
import ajax from '../ajax.js';

export default function(settings) {
	var table, modal, parent, api, filters = [], toggle = [],
		server = settings.options.serverSide,
		extra = settings.extra,
		language = server ? {search: ''} : {},
		route = config.route;

	settings.columns.push({visible: false, searchable: false, orderable: false});

	//Set up the table.
	table = $("#" + settings.id);
	filters = table.prev('.dataTables_filters');

	table.DataTable($.extend(settings.options, {
		orderCellsTop: true,
		autoWidth: false,
		processing: false,
		columns: settings.columns,
		language: language,

		ajax: !server ? null : async function(data, callback) {
			data.option = 'list';
			data.model = settings.model;
			data.route = route;

			if(settings.filter && filters && filters.length) {
				data.search_values = filters.find('form').serialize();
				// for(var i in data) {
				// 	if(data[i].name === 'sSearch') {
				// 		data.splice(i, 1);
				// 	}
				// }
			}

			var $this = $(this).addClass('table-processing');
			$this.find('tbody').attr('aria-busy', true);
			$this.find('caption').text("Loading new data, please wait.");

			try {
				const res = await ajax({
					action: 'dataTables',
					data,
				});

				callback(res);
				$this.find('caption').text(res.caption);
				if(toggle.length) {
					toggle.removeClass('btn-success').addClass('btn-default disabled').attr('aria-disabled', true);
				}
			} catch { false; }

			$this.removeClass('table-processing').find('tbody').attr('aria-busy', false);
		},

		createdRow: function(row, data) {
			var $row = $(row),
				cells = $row.find('td');
			$row.data('id', data[data.length - 1]);
			cells.filter(':not(:has(.btn))').attr('tabindex', '0');
			$row.find('.btn-merge, .btn-print').attr({role: 'checkbox', 'aria-checked' : 'false'});
			$row.find('.btn-edit:not([href="#"])').attr('target', '_blank');
		},
	}));

	window.test = table;

	parent = table.parent();
	api = table.DataTable();

	window.api = api;

	if(parent.next().is('.btn[data-toggle="modal"]')) {
		parent.next().appendTo(parent.find('.dataTables_add'));
	}
	//Filters
	if(settings.filter && filters.length) {
		//If we want to use our own filters, and if they exist
		parent.find('.dataTables_filter').remove();
		filters.insertBefore(table);
		if(server) {
			filters.find('form').on('submit', function(e, isCsv = false){
				if (!isCsv) {
					e.preventDefault();
					api.draw();
				}
			});
			filters.find(':reset').on('click', function(e){
				e.preventDefault();
				filters.find('form')[0].reset();
				api.draw();
			});
		} else {
			filters.find(':input').on('change', function(){
				api.search(this.value, $(this).data('index')).draw();
			});
		}

		filters.find('.btn-csv').on('click', async function(e) {
			e.stopImmediatePropagation();
			e.preventDefault();

			const settings = api.settings()[0];
			const data = api.ajax.params(settings.aoData);
			const pagingInfo = api.page.info();
			const form = filters.find('form');

			form.find('input:hidden:not(.requiredValue)').remove();

			if (filters && filters.length) {
				data.search_values = form.serialize();

				delete data.search;
			}

			data.start = 0;
			data.length = pagingInfo.recordsTotal;
			data.option = 'csv';
			data.route = config.route;

			for (const key of Object.keys(data)) {
				form.append(
					$('<input type="hidden" name="' + key + '">').val(data[ key ]));
			}

			form.trigger('submit', true);
		})
	} else {
		//Nope?  Let's fall back to the original stuff then!
		parent.find('.dataTables_filter input').addClass('form-control');
		parent.find('.dataTables_filter input').attr('type', 'search');
		if(server) {
			parent.find('.dataTables_filter input').unbind().wrap('<div class="input-group"></div>');
			parent.find('.dataTables_filter .input-group')
				.append('<div class="input-group-btn"><button class="btn btn-primary" type="submit">Search</button></div>')
				.wrap('<form method="post" action=""></form>');
			parent.find('.dataTables_filter form').submit(function(e) {
				e.preventDefault();
				api.search($(this).find('input').val()).draw();
			});
		}
	}

	//Accessibility tweaks to the table
	parent.attr('role', '');
	table.attr({
		role: 'main',
		'aria-label': settings.title + ' table',
	});
	table.find('tbody').attr('role', '');
	parent.find('.dataTables_filter').attr({
		role: 'search',
		'aria-label': settings.title + ' search',
		'aria-controls' : settings.id,
	});
	parent.find('.dataTables_paginate').attr({
		role: 'navigation',
		'aria-label': settings.title + ' pagination',
		'aria-controls' : settings.id,
	});
	parent.find('.dataTables_length select').addClass('form-control');
	if(server) {
		table.prepend('<caption id="' + settings.id + '-caption" role="status" aria-live="assertive" aria-relevant="additions"></caption>');
	} else {
		table.removeClass('table-caption');
	}

	//Initialize the modal as well.
	modal = $('#' + settings.modal).on('hidden.bs.modal.default', function() {
		$(this).find('form')[0].reset();
		$(this).find('.hide-remove').remove();
	});

	const modalSubmit = async function(opts) {
		if(typeof opts !== 'object') {
			opts = {};
		}
		var form = opts.form,
			row = opts.row,
			callback = opts.callback,
			$form = $(form),
			data = $form.serializeArray(),
			act = $form.find(':input[name="_action"]').length ?
					$form.find(':input[name="_action"]').val() :
					'manage';

		data = new FormData(form);
		data.append('route', route);

		let res;

		try {
			res = await ajax({
				action: act,
				data,
			});
		} catch {
			return;
		}

		if('dialog' in res && res.dialog === true) {
			$($.parseHTML(res.html)).initModal(function() {
				modalSubmit({
					form: this,
					row: row,
					callback: function() {
						$form
							.append('<input type="hidden" name="_force" value="1">')
							.trigger('submit');
					},
				});
			});
		} else {
			$form.closest('.modal').forceHide();

			const page = api.page();

			if(server) {
				if(typeof callback === 'function') {
					callback();
				}
				api.page(page).draw('page');
			} else {
				if(typeof callback === 'function') {
					callback();
				}
				if(typeof row === 'undefined') {
					api.row.add(res.data).page(page).draw('page');
				} else {
					api.row(row[0]).data(res.data).page(page).draw('page');
				}
			}
		}
	};

	//Inserting
	modal.find('form').on('submit', function(e) {
		e.preventDefault();
		modalSubmit({form: this});
	});

	//Editing
	table.on('click', '.btn-edit[href="#"]', async function(e) {
		e.preventDefault();
		var row = $(this).closest('tr'), PK = row.data('id');
		const res = await ajax({
			action: 'dialog',
			data: {model: settings.model, PK: PK, route: route},
		});

		$($.parseHTML(res.html)).initModal(function() {
			modalSubmit({form: this, row: row});
		});
	});

	//Deleting
	table.on('click', '.btn-delete[href="#"]', async function(e) {
		e.preventDefault();
		const row = $(this).closest('tr');
		const PK = row.data('id');

		if(confirm("Are you sure you wish to delete this?")) {
			try {
				await ajax({
					action: 'delete',
					data: {model: settings.model, PK: PK},
				});

				const page = api.page();

				if(server) {
					api.page(page).draw('page');
				} else {
					api.row(row[0]).remove().page(page).draw('page');
				}
			} catch { false; }
		}
	});

	//Merging (if available)
	toggle = parent.find('.btn-toggle-submit');
	table.on('click', '.btn-merge, .btn-print', function(e) {
		e.preventDefault();

		if($(this).hasClass('disabled')) {
			return;
		}

		$(this).toggleClass('btn-success btn-default').attr('aria-checked', $(this).hasClass('btn-success'));

		var row = $(this).closest('tr'), total, buttons = parent.find('.btn-merge, .btn-print');
		row.toggleClass('success');
		total = buttons.filter('.btn-success').length;

		if(typeof extra['toggle-limit'] !== 'undefined' && total >= extra['toggle-limit']) {
			buttons.filter('.btn-default').addClass('disabled').attr('aria-disabled', true);
		} else {
			buttons.filter('.btn-default').removeClass('disabled').attr('aria-disabled', false);
		}

		if(total >= (typeof extra['toggle-minimum'] === 'undefined' ? 1 : extra['toggle-minimum'])) {
			toggle.addClass('btn-success').removeClass('btn-default disabled').attr('aria-disabled', false);
		} else {
			toggle.removeClass('btn-success').addClass('btn-default disabled').attr('aria-disabled', true);
		}
	});

	return { table, modal, toggle, filters, extra, api, model: settings.model };
}