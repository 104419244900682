import { render, staticRenderFns } from "./header.vue?vue&type=template&id=81918a88&"
import script from "./header.vue?vue&type=script&lang=js&"
export * from "./header.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/source/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('81918a88')) {
      api.createRecord('81918a88', component.options)
    } else {
      api.reload('81918a88', component.options)
    }
    module.hot.accept("./header.vue?vue&type=template&id=81918a88&", function () {
      api.rerender('81918a88', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "js/pages/dataEntry/components/header.vue"
export default component.exports