var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: "tab-pane " + (_vm.defaultTab ? "active" : ""),
      attrs: { id: "data-entry-" + _vm.tabKey }
    },
    [
      _c("h2", [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _vm.people.length === 0 && _vm.filterText
        ? _c("entry-filter-no-results")
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.people, function(person) {
        return _c("entry-group", {
          key: person.id,
          attrs: {
            person: person,
            date: _vm.date,
            agency: _vm.agency,
            imported: _vm.imported,
            data_months: _vm.data_months,
            "is-single": false
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }