import $ from '../jquery.js';

const jobsDialog = {};

jobsDialog.init = function() {
	$(document).on('change.seasonal', '.jobs-dialog [name="flag_seasonal"]', function() {
		$(this).closest('.modal').find('.jobs_seasonal_months').toggleClass('hide', !$(this).prop('checked'))
	})
};

export default jobsDialog;
