import initDataTables from './elements/dataTables.js';

const DataTablesBase = function() {
	return this;
};

DataTablesBase.prototype.init = function() {
	this.dataTable();

	if (typeof this.selectors === 'function') {
		this.selectors.call(this);
	}
	if (typeof this.bindings === 'function') {
		this.bindings.call(this);
	}
};

DataTablesBase.prototype.dataTable = function() {
	const data = initDataTables(window.dataTable);

	this.table = data.table;
	this.modal = data.modal;
	this.api = data.api;

	if (typeof data.toggle !== 'undefined') {
		this.toggle = data.toggle;
	}

	if (typeof data.filters !== 'undefined') {
		this.filters = data.filters;
	}

	this.model = data.model;
};

export default DataTablesBase;
