<template>
	<div class="modal fade" ref="modal" v-if="isOpen">
		<div class="modal-dialog">
			<div class="modal-content">
				<form :class="`form${horizontal ? '-horizontal' : ''}`" @submit="submit" ref="form">
					<div class="modal-header">
						<button type="button" class="close glyphicon glyphicon-remove" data-dismiss="modal" aria-hidden="true"></button>
						<h4 class="modal-title" v-if="title">{{ title }}</h4>
					</div>

					<div class="modal-body">
						<slot />
					</div>

					<div class="modal-footer" v-if="form">
						<button type="submit" class="btn btn-primary">Save and Close</button>
						<button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
	import $ from '../jquery.js';

	export default {
		props: [ 'title', 'form', 'horizontal' ],

		data() {
			return {
				modal: null,
				isOpen: false,
			}
		},

		methods: {
			close: function() {
				console.log('close');
			},

			show() {
				this.isOpen = true;

				this.$nextTick(() => {
					this.init();
				});
			},

			hide() {
				this.modal.modal('hide');
			},

			submit(e) {
				e.preventDefault();

				this.$emit('submit', this.serialize());
			},

			serialize() {
				return $(this.$refs.form).serializeJSON();
			},

			init() {
				this.modal = $(this.$refs.modal);

				this.modal.on('hidden.bs.modal', () => {
					this.$emit('hidden');
					this.isOpen = false;
				});

				this.modal.modal('show');
			},
		},
	}
</script>
