import $ from '../jquery.js';
import DataTablesBase from '../datatables.js';
import ajax from '../ajax.js';

var People = new DataTablesBase();

People.dialog = {};
People.dialog.agencyView = $("#people-add-dialog-agency-view");
People.dialog.form = $("#people-add-dialog-form");
People.dialog.mciForm = $("#people-add-dialog-mci");
People.dialog.main = $("#people-add-dialog-main");

People.bindings = function() {
	var that = this;

	that.modal.on('hidden.bs.modal.bs.modal.reset', function() {
		that.dialog.mciForm.removeClass('hide')[0].reset();
		that.dialog.form.addClass('hide').find('> div').empty();
	});

	that.modal.on('click', '.people-add-dialog-proceed', async function(e) {
		e.preventDefault();
		var add = that.dialog.form.find('[name="add"]:checked').val() === '1',
			data = that.dialog.form.find('.people-add-dialog-peopleagency').serializeArray();
		if(add) {
			data.push({name: 'model', value: 'PeopleAgencies'});
			data.push({name: 'datatable', value: '0'});

			await ajax({
				action: 'manage',
				data,
			});

			that.dialog.form.find('.hide').removeClass('hide');
			that.dialog.form.find('.people-add-dialog-exists').remove();
			that.dialog.form.focusFirst();
		} else {
			that.modal.forceHide();
		}
	});

	that.dialog.mciForm.unbind('submit').submit(async function(e) {
		e.preventDefault();
		const res = await ajax({
			action: 'check_mci',
			data: $(this).serializeJSON(),
		});

		that.dialog.mciForm.addClass('hide')[0].reset();
		$($.parseHTML(res.html)).appendTo(that.dialog.form.removeClass('hide').find('> div').empty());
		that.dialog.form.focusFirst();
	});

	if(that.dialog.agencyView.length) {
		that.dialog.agencyView.find('a[data-agency_id]').click(async function(e) {
			e.preventDefault();
			const agency = $(this).data('agency_id');

			await ajax({
				action: 'alt_view',
				data: { agency_id: agency, clear: true },
			});

			that.dialog.form.find('[name="agency_id"]').val(agency);
			that.agencyViewCallback(agency);
			that.modal.on('hide.bs.modal.reload', function(e) {
				e.preventDefault();
				window.location.reload();
			});
		});
	}

	if(that.toggle) {
		that.toggle.click(async function(e) {
			e.preventDefault();

			if($(this).hasClass('disabled')) {
				return;
			}

			const PK = that.table.find('tr.success').pluck('id');

			const res = await ajax({
				action: 'merge',
				data: {model: 'People', PK: PK},
			});

			const page = that.api.page();

			const modal = $($.parseHTML(res.html)).initModal(async function() {
				if(!confirm("Are you sure you wish to merge these two People?  This is irreversible!")) {
					return;
				}

				await ajax({
					action: 'merge',
					data: $(this).serializeArray(),
				});

				modal.forceHide();
				that.api.page(page).draw('page');
			});

			that.mergeDialogInit(modal);
		});
	}
};

People.agencyViewCallback = function() {
	this.dialog.agencyView.remove();
	this.dialog.main.removeClass('hide');
	//this.dialog.form.find('[name$="[agency_id]"]').val(agency_id);
};

People.mergeDialogInit = function(modal) {
	var step5 = modal.find('div.people-merge-dialog-5');
	modal.find('.btn-next').click(function(e) {
		e.preventDefault();
		var step = parseInt(modal.attr('data-step'), 10) + 1, tables = {};
		if(step === 5) {
			//Clone all of the tables
			tables.aes = modal.find('.people-merge-dialog-aes').clone();
			tables.agencies = modal.find('.people-merge-dialog-agencies').clone();
			tables.scos = modal.find('.people-merge-dialog-scos').clone();
			tables.jobs = modal.find('.people-merge-dialog-jobs').clone();

			//Modify the AEs
			tables.aes.find('tr').filter(':has(:checkbox:not(:checked))').remove();
			tables.aes.find('td, th').filter(':empty, :has(:checkbox)').remove();
			tables.aes.find('th[colspan]').attr('colspan', function(i, attr) {
				return parseInt(attr, 10) - 1;
			});

			//Modify the Jobs
			tables.jobs.find('tr').filter(':has(:checkbox:not(:checked))').remove();
			tables.jobs.find('td, th').filter(':empty, :has(:checkbox)').remove();
			tables.jobs.find('th[colspan]').attr('colspan', function(i, attr) {
				return parseInt(attr, 10) - 1;
			});

			//Let's build the info table now.
			tables.info = "<table class='table table-striped table-condensed table-bordered'><thead><tr>";
			modal.find('.table-merge tbody th').each(function() {
				tables.info += '<th>' + $(this).find('span:first').text() + '</th>';
			});
			tables.info += '</tr></thead><tbody><tr>';
			modal.find('.table-merge tbody td').filter(':has(:radio:checked)').each(function() {
				tables.info += '<td>' + $(this).text() + '</td>';
			});
			tables.info += '</tr></tbody></table>';
			tables.info = $.parseHTML(tables.info);

			step5
				.append(tables.info).append('<hr>')
				.append(tables.aes).append('<hr>')
				.append(tables.scos).append('<hr>')
				.append(tables.agencies).append('<hr>')
				.append(tables.jobs);
		}
		modal.attr('data-step', step);
	});
	modal.find('.btn-previous').click(function(e) {
		e.preventDefault();
		var step = parseInt(modal.attr('data-step'), 10) - 1;
		if(step === 4) {
			step5.find('table, hr').remove();
		}
		modal.attr('data-step', step);
	});
};

/*setTimeout(function() {
	People.table.find('tbody tr:lt(2) .btn-merge').trigger('click');
	People.merge.trigger('click');
}, 1500);*/

export default People;