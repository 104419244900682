import $ from '../jquery.js';

var Dashboard = {};

Dashboard.tabs = $('#dashboard-tabs');

Dashboard.init = function() {
	this.bindings();
};

Dashboard.tab = function(link, pop) {
	link.tab('show');
	if(!pop) {
		window.history.pushState({tab: link.data('target')}, null, link[0].href);
	}
};

Dashboard.bindings = function() {
	var that = this;

	//Tab navigation - but make them normal links if no pushState available.
	$('#dashboard-nav a').on({
		click: function(e, pop) {
			e.preventDefault();
			var link = $(this);
			that.tab(link, pop);
		},
		shown: function() {
			that.focus();
		},
	});

	//popstate handler
	$(window).on('popstate', function(e) {
		var tab = (e.originalEvent.state !== null && typeof e.originalEvent.state.tab === 'string') ? e.originalEvent.state.tab : undefined;
		if(typeof tab === 'string') {
			$('#dashboard-nav a[data-target="' + tab + '"]').trigger('click', true);
		}
	});
};

export default Dashboard;
