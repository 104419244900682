import DataTablesBase from '../datatables.js';

var Misc = {};

Misc.init = function() {
	var DataTable = new DataTablesBase();
	DataTable.init();
	window.DataTable = DataTable;
};

export default Misc;