<template>
	<div
		:class="`tab-pane ${defaultTab ? 'active' : ''}`"
		:id="`data-entry-${tabKey}`">
		<h2>{{ title }}</h2>

		<entry-filter-no-results
			v-if="people.length === 0 && filterText"></entry-filter-no-results>

		<entry-group
			v-for="person in people"
			:person="person"
			:date="date"
			:agency="agency"
			:imported="imported"
			:data_months="data_months"
			:is-single="false"
			:key="person.id"></entry-group>
	</div>
</template>

<script>
	export default {
		props: [ 'defaultTab', 'tabKey', 'filterText', 'title', 'people', 'date', 'agency', 'imported', 'data_months' ],
	}
</script>
