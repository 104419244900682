import $ from '../jquery.js';
import initDataTables from '../elements/dataTables.js';
import ajax from '../ajax.js';
import config from '../config.js';

const Agency = {};

Agency.single = true;
Agency.close = false;
Agency.form = $('#agency-form');
Agency.tabs = $('#agency-tabs');
Agency.files = {widthMin: 50, heightMin: 50, size: 4000000};

Agency.tables = {};
Agency.modals = {};
Agency.toggles = {};

Agency.init = function() {
	this.dataTables();
	this.bindings();
}

Agency.dataTables = function() {
	// Set up data tables
	for (const [ key, value ] of Object.entries(window.dataTables)) {
		const data = initDataTables(value);
		this.tables[ key ] = data.table;
		this.modals[ key ] = data.modal;
		this.toggles[ key ] = data.toggle;
	}
}

Agency.fixUrl = function() {
	const url = this.form.find('[name="website"]');
	const val = url[0].value;

	if (!val.match(/^http/) && val.length) {
		url.val('http://' + val);
	}
};

Agency.fileChange = function() {
	var that = Agency,
		$this = this;
	if(typeof $this.files !== 'undefined' && typeof $this.files[0] !== 'undefined') {
		if($this.files[0].type !== 'image/jpeg' && $this.files[0].type !== 'image/png') {
			alert('Please choose a JPEG or PNG image.');
		} else if($this.files[0].size > that.files.size) {
			alert('Please choose a file smaller than ' + that.files.size + '.');
		} else {
			var img = new Image(), _URL = window.URL || window.webkitURL;
			img.onload = function() {
				if(this.height < that.files.heightMin || this.width < that.files.widthMin) {
					alert('Please choose an image that is at least ' + that.files.widthMin + 'x' + that.files.heightMin + 'px');
					$this.value = '';
				} else {
					$($this).prev().find('figure').css('background-image', "url('" + this.src + "')");
				}
			};
			img.src = _URL.createObjectURL($this.files[0]);
		}
	}
};

Agency.bindings = function() {
	var that = this;

	//Fix the URL input - make sure it has a protocol
	that.form.find('[type="url"]').blur(function() {
		that.fixUrl();
	});

	//Make sure we go to the right place when we submit the form
	that.form.find(':submit').click(function() {
		that.fixUrl();
		that.close = !!$(this).data('close');
	});

	//Submit the form
	that.form.submit(async function(e) {
		e.preventDefault();
		try {
			await ajax({
				action: 'manage',
				data: new FormData(this),
			});
		} catch { false; }

		alert("Data was successfully saved!");

		if(that.close) {
			if(typeof window.opener !== 'undefined') {
				window.close();
			} else {
				window.location = config.url.base + 'agencies';
			}
		}
	});

	//Tab navigation - but make them normal links if no pushState available.
	$('#agency-nav a').on({
		click: function(e, pop) {
			e.preventDefault();
			$(this).tab('show');
			if(!pop) {
				window.history.pushState({tab: $(this).data('target')}, null, this.href);
			}
		},
		shown: function() {
			that.focus();
		},
	});

	//popstate handler
	$(window).on('popstate', function(e) {
		var tab = (e.originalEvent.state !== null && typeof e.originalEvent.state.tab === 'string') ? e.originalEvent.state.tab : undefined;
		if(typeof tab === 'string') {
			$('#agency-nav a[data-target="' + tab + '"]').trigger('click', true);
		}
	});

	//Lots of fun stuff for file inputs
	that.form.find('[type="file"]').on('change', Agency.fileChange);

	$(document).on('change', '.agencysuccessstories-dialog input[type="file"]', Agency.fileChange);

	$(document).on('click', '#agencysuccessstories-table .btn-feature', async function(e) {
		e.preventDefault();

		const row = $(this).closest('tr');

		const data = {
			agency_success_story_id: row.data('id'),
			model: 'AgencySuccessStories',
			flag_report: 1,
			return: 'datatable',
		};

		try {
			const res = await ajax({
				action: 'manage',
				data: data,
			});

			Agency.tables.successStories.DataTable().rows().every(function() {
				const data = this.data();

				data[2] = 'No';
				Agency.tables.successStories.DataTable().row(this).data(data);
			});

			Agency.tables.successStories.DataTable().row(row[0]).data(res.data);
		} catch { false; }
	});
};

Agency.focus = function() {
	this.tabs.find('.active').focusFirst();
};

window.Agency = Agency;

export default Agency;