var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "panel panel-default" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "form",
        attrs: { method: "post", action: "" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "panel-body" },
          [
            _c("h4", [_vm._v("Import file")]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col col-sm-4" }, [
                _c("input", {
                  ref: "file",
                  staticClass: "form-control",
                  attrs: {
                    type: "file",
                    name: "import_file",
                    accept: "text/csv",
                    id: "data-import-file",
                    required: ""
                  }
                })
              ]),
              _vm._v(" "),
              _vm._m(1)
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("h4", [_vm._v("Import type")]),
            _vm._v(" "),
            _c("label", { staticClass: "radio-inline" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.import_type,
                    expression: "import_type"
                  }
                ],
                attrs: { type: "radio", name: "import_type", value: "agency" },
                domProps: { checked: _vm._q(_vm.import_type, "agency") },
                on: {
                  change: function($event) {
                    _vm.import_type = "agency"
                  }
                }
              }),
              _vm._v("\n\t\t\t\tAgency Support\n\t\t\t")
            ]),
            _vm._v(" "),
            _c("label", { staticClass: "radio-inline" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.import_type,
                    expression: "import_type"
                  }
                ],
                attrs: { type: "radio", name: "import_type", value: "race" },
                domProps: { checked: _vm._q(_vm.import_type, "race") },
                on: {
                  change: function($event) {
                    _vm.import_type = "race"
                  }
                }
              }),
              _vm._v("\n\t\t\t\tRace\n\t\t\t")
            ]),
            _vm._v(" "),
            _c("label", { staticClass: "radio-inline" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.import_type,
                    expression: "import_type"
                  }
                ],
                attrs: {
                  type: "radio",
                  name: "import_type",
                  value: "ethnicity"
                },
                domProps: { checked: _vm._q(_vm.import_type, "ethnicity") },
                on: {
                  change: function($event) {
                    _vm.import_type = "ethnicity"
                  }
                }
              }),
              _vm._v("\n\t\t\t\tEthnicity\n\t\t\t")
            ]),
            _vm._v(" "),
            _vm.drafts
              ? [
                  _c("hr"),
                  _vm._v(" "),
                  _c("h4", [_vm._v("Saved Drafts")]),
                  _vm._v(" "),
                  _c(
                    "ul",
                    _vm._l(_vm.drafts, function(draft) {
                      return _c("li", [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.setDraft(draft)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\tDraft saved on " +
                                _vm._s(_vm.date(draft)) +
                                " (" +
                                _vm._s(draft.import_type) +
                                ")\n\t\t\t\t\t\t"
                            )
                          ]
                        )
                      ])
                    }),
                    0
                  )
                ]
              : _vm._e()
          ],
          2
        ),
        _vm._v(" "),
        _c("import-footer", { attrs: { firstStep: true } })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-heading" }, [
      _c(
        "label",
        { staticClass: "control-label", attrs: { for: "data-import-file" } },
        [_vm._v("Choose a file to import.")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col col-sm-8" }, [
      _c("p", { staticClass: "form-control-static" }, [
        _c("em", [_vm._v("File must be a CSV.")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }