var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "panel panel-default" }, [
    _c("div", { staticClass: "panel-heading" }, [
      _c("strong", [
        _vm._v(
          "Match " +
            _vm._s(_vm.config.text.plural) +
            " from the report with " +
            _vm._s(_vm.config.text.plural) +
            " in the system"
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "form",
      { staticClass: "form-horizontal", on: { submit: _vm.handleSubmit } },
      [
        _c(
          "div",
          { staticClass: "panel-body" },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-6 col-md-4 text-right" }, [
                _c("strong", [
                  _vm._v(
                    "Imported " +
                      _vm._s(_vm.config.text.singular) +
                      " from file"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6 col-md-4" }, [
                _c("strong", [
                  _vm._v(
                    "Existing " +
                      _vm._s(_vm.config.text.singular) +
                      " in EmployStat"
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _vm._l(_vm.matches, function(match, index) {
              return _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-6 col-md-4 control-label",
                    attrs: { for: "match-" + index }
                  },
                  [_vm._v("\n\t\t\t\t\t" + _vm._s(match) + ":\n\t\t\t\t")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col col-sm-6 col-md-4" }, [
                  _c(
                    "select",
                    {
                      ref: "match" + index,
                      refInFor: true,
                      staticClass: "form-control",
                      attrs: { id: "match-" + index, name: index, required: "" }
                    },
                    [
                      _c("option", { attrs: { value: "-1" } }, [
                        _vm._v(
                          "(skip this " + _vm._s(_vm.config.text.singular) + ")"
                        )
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.config.match_values, function(matchValue) {
                        return _c(
                          "option",
                          {
                            domProps: {
                              value: matchValue.id,
                              selected:
                                _vm.getDefaultEntry(match) === +matchValue.id
                            }
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(matchValue[_vm.config.name_column]) +
                                "\n\t\t\t\t\t\t"
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                ])
              ])
            })
          ],
          2
        ),
        _vm._v(" "),
        _c("import-footer", {
          on: {
            back: function($event) {
              return _vm.$emit("back")
            },
            saveDraft: function($event) {
              _vm.$emit("saveDraft", _vm.getData())
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }