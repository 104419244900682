var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isMultiple
    ? _c("entry-multiple", {
        attrs: {
          people: _vm.people,
          entries: _vm.entries,
          date: _vm.date,
          agency: _vm.agency,
          data_months: _vm.data_months
        }
      })
    : _vm.isSingle
    ? _c(
        "div",
        _vm._l(_vm.people, function(person) {
          return _c("entry-group", {
            key: person.id,
            attrs: {
              person: person,
              date: _vm.date,
              agency: _vm.agency,
              imported: false,
              data_months: _vm.data_months,
              "is-single": true
            }
          })
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }