import config from '../config.js';
import $ from '../jquery.js';
import ajax from '../ajax.js';

let CKEDITOR;

let Discussion = {};

Discussion.init = function() {
	$(() => {
		CKEDITOR = window.CKEDITOR;
		
		if(config.url.data[1]) {
			if(typeof config.url.data[2] === 'undefined') {
				CKEDITOR.replace("discussion-post-box");
			} else {
				CKEDITOR.replace("discussion-comment-box");
			}
		}
	});

	var submitting = false;

	$("#discussion-post-form").submit(async function(e){
		e.preventDefault();
		var form = $(this),
			button = form.find('button[type="submit"]');
		if(submitting) {
			alert("Please wait for your last request to finish");
			return;
		}
		var message = CKEDITOR.instances['discussion-post-box'].getData();
		form.find('textarea').val(message);
		if(message.length === 0) {
			alert("You did not enter any discussion text");
			return;
		}

		submitting = true;
		button.button('loading');

		try {
			const res = await ajax({
				action: 'Discussion',
				data: form.serializeJSON(),
			});

			var article = $($.parseHTML(res.html)).addClass('fade');

			$("#Discussions > .alert").remove();
			$(document).scrollTop(0);
			article.prependTo('#Discussions').addClass('in');

			form.find('textarea').val('');
			CKEDITOR.instances['discussion-post-box'].setData('');

			submitting = false;
			button.button('reset');
		} catch {
			submitting = false;
			button.button('reset');
		}
	});

	$(document).on('submit', ".discussion-comment-form", async function(e){
		e.preventDefault();
		var form = $(this),
			button = form.find('button[type="submit"]');
		if(button.is('disabled')) {
			return;
		}
		if(submitting) {
			alert("Please wait for your last request to finish");
			return;
		}
		var message = CKEDITOR.instances['discussion-comment-box'].getData();
		form.find('textarea').val(message);
		if(message.length === 0) {
			alert("You did not enter a comment");
			return;
		}
		submitting = true;
		button.button('loading');

		try {
			const res = await ajax({
				action: 'Discussion',
				data: form.serializeJSON(),
			});

			var aside = $($.parseHTML(res.html)).addClass('fade');
			if(res.parent_id > 0) {
				//$("#discussion-comment-replies-" + res.parent_id).append(aside);
				//form.find("input:hidden[name='parent_id']").val('0');
				aside.insertBefore(form);
			} else {
				form.prev().append(aside);
			}

			aside.addClass('in');

			form.find('textarea').val('');
			CKEDITOR.instances['discussion-comment-box'].setData('');

			submitting = false;
			button.button('reset');
		} catch {
			submitting = false;
			button.button('reset');
		}
	}).on('click', '.discussion-short-display > a a', function(e){
		e.stopImmediatePropagation();
	}).on('click', '.EditPostLink', function(e){
		e.preventDefault();
		var article = $(this).closest('.discussion'),
			post_id = article.data('post_id'),
			text = $("#discussion-post-" + post_id + "-text");
		text.data('original', text.html());
		CKEDITOR.replace("discussion-post-" + post_id + "-text");
		article.find('.discussion-post-edit-actions-container').removeClass('hide').prev().addClass('hide');
	}).on('click', '.discussion-post-cancel-edit', function(e){
		e.preventDefault();
		var article = $(this).closest('.discussion'),
			post_id = article.data('post_id'),
			text = $("#discussion-post-" + post_id + "-text");

		CKEDITOR.instances['discussion-post-' + post_id + '-text'].destroy();
		text.html(text.data('original')).data('original', '');
		article.find('.discussion-post-edit-actions-container').addClass('hide').prev().removeClass('hide');
	}).on('click', '.discussion-post-save-edit', async function(e){
		e.preventDefault();
		var article = $(this).closest('.discussion'),
			post = article.find('.post-text > span'),
			post_id = article.data('post_id'),
			ck = CKEDITOR.instances['discussion-post-' + post_id + '-text'];
		if(submitting) {
			alert("Please wait for your last request to finish");
			return;
		}
		submitting = true;
		var message = ck.getData();
		if(message.length === 0) {
			alert("You did not enter a post");
			return;
		}

		const res = await ajax({
			action: 'Discussion',
			data: {post_id: post_id, post_message: message, method: 'editPost'},
		});
		ck.destroy();
		article.find('.discussion-post-edit-actions-container').addClass('hide').prev().removeClass('hide');
		var newPost = $($.parseHTML(res.html));
		newPost.addClass('fade');
		post.fadeOut();
		post.replaceWith(newPost);
		newPost.addClass('in');
		submitting = false;
	}).on('click', '.EditCommentLink', function(e){
		e.preventDefault();
		var aside = $(this).closest('.discussion-comment'),
			comment_id = aside.data('comment_id'),
			text = $("#discussion-comment-" + comment_id + "-text");
		text.data('original', text.html());
		CKEDITOR.replace("discussion-comment-" + comment_id + "-text");
		aside.find('.discussion-comment-edit-actions-container').removeClass('hide').prev().addClass('hide');
	}).on('click', '.discussion-comment-cancel-edit', function(e){
		e.preventDefault();
		var aside = $(this).closest('.discussion-comment'),
			comment_id = aside.attr('data-comment_id'),
			text = $("#discussion-comment-" + comment_id + "-text");

		CKEDITOR.instances['discussion-comment-' + comment_id + '-text'].destroy();
		text.html(text.data('original')).data('original', '');
		aside.find('.discussion-comment-edit-actions-container').addClass('hide').prev().removeClass('hide');
	}).on('click', '.discussion-comment-save-edit', async function(e){
		e.preventDefault();
		var aside = $(this).closest('.discussion-comment'),
			comment = aside.find('.comment-text > span'),
			comment_id = aside.attr('data-comment_id'),
			ck = CKEDITOR.instances['discussion-comment-' + comment_id + '-text'];
		if(submitting) {
			alert("Please wait for your last request to finish");
			return;
		}
		submitting = true;
		var message = ck.getData();
		if(message.length === 0) {
			alert("You did not enter a comment");
			return;
		}
		const res = await ajax({
			action: 'Discussion',
			data: {comment_id: comment_id, comment_message: message, method: 'editComment'},
		});

		var newAside = $($.parseHTML(res.html));
		newAside.find('.comment-text').addClass('fade');
		comment.fadeOut();
		aside.replaceWith(newAside);
		newAside.find('.comment-text').addClass('in');
		submitting = false;
	}).on('click', '.DeleteCommentLink', async function(e){
		e.preventDefault();
		var aside = $(this).closest('.discussion-comment'),
			comment_id = aside.attr('data-comment_id');
		if(submitting) {
			alert("Please wait for your last request to finish");
			return;
		}
		if(!confirm("Are you sure you want to delete your comment?")) {
			return;
		}

		submitting = true;

		try {
			await ajax({
				action: 'Discussion',
				data: {comment_id: comment_id, method: 'deleteComment'},
			});
			aside.fadeOut(1500);
			submitting = false;
		} catch {
			submitting = false;
		}
	}).on('click', '.discussion-comment-reply', function(e) {
		e.preventDefault();
		var aside = $(this).closest('.discussion-comment'),
			comment_id = aside.data('comment_id'),
			article = aside.closest('article'),
			form = article.find('.discussion-comment-form');

		CKEDITOR.instances['discussion-comment-box'].destroy();
		form.appendTo('#discussion-comment-replies-' + comment_id);
		CKEDITOR.replace('discussion-comment-box');
		form.find("input:hidden[name='parent_id']").val(comment_id);
		article.find('.discussion-comment-cancel-reply-container').removeClass('hide');
	}).on('click', '.discussion-comment-cancel-reply', function(e){
		e.preventDefault();
		var article = $(this).closest('.discussion'),
			form = article.find('.discussion-comment-form');

		CKEDITOR.instances['discussion-comment-box'].destroy();
		form.appendTo(article);
		CKEDITOR.replace('discussion-comment-box');
		form.find("input:hidden[name='parent_id']").val('0');
		article.find('.discussion-comment-cancel-reply-container').addClass('hide');
	}).on('click', '.discussion-comment-best', async function(e){
		e.preventDefault();
		var that = $(this),
			comment_id = that.closest('aside').data('comment_id'),
			article = that.closest('article'),
			hasBest = article.data('best_comment_id');

		if(that.hasClass('active')) {
			return;
		}

		if(hasBest) {
			if(!confirm("You previously selected another Best Answer for this post; do you wish to select this one as Best Answer instead?")) {
				return;
			}
		}

		await ajax({
			action: 'Discussion',
			data: {comment_id: comment_id, method: 'selectBestAnswer'},
		});

		that.button('toggle');
		var tmp, newValue;

		if(hasBest) {
			$("#discussion-comment-" + hasBest).find('.discussion-comment-best').toggle();
			tmp = $("#discussion-comment-" + hasBest).find('.discussion-comment-votes strong');
			tmp.parent().html('<strong>' + (newValue = parseInt(tmp.text(), 10) - 1).toString() + '</strong> user' + (newValue === 1 ? ' has' : 's have') + ' chosen this as the Best Answer');
		}

		tmp = $("#discussion-comment-" + comment_id).find('.discussion-comment-votes strong');
		tmp.parent().html('<strong>' + (newValue = parseInt(tmp.text(), 10) + 1).toString() + '</strong> user' + (newValue === 1 ? ' has' : 's have') + ' chosen this as the Best Answer');

		article.data('best_comment_id', comment_id);
	}).on('click', '.discussion-post-subscribe', async function(e){
		e.preventDefault();
		var that = $(this),
			post_id = that.closest('article').data('post_id'),
			action = that.is('.active') ? 'unsubscribe' : 'subscribe';

		if(!confirm("Are you sure you want to " + action + " " + (action === 'subscribe' ? 'to' : 'from') + " this discussion?")) {
			return;
		}

		await ajax({
			action: 'Discussion',
			data: {post_id: post_id, method: action},
		});

		that.toggleClass('active').button(action + 'd');
	}).on('click', '.pagination a', function(e) {
		e.preventDefault();
		document.location = config.url.base + 'discussion/' + (config.url.data[1] ? config.url.data[1] + '/' : '') + $(this).data('page');
	});
}

export default Discussion;