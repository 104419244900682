import $ from '../jquery.js';
import config from '../config.js';
import ajax from '../ajax.js';
import agencyView from '../elements/agencyView.js';

var PeopleUpdate = {};

PeopleUpdate.init = function() {
	this.selectors();
	this.bindings();
	agencyView.init();
};

PeopleUpdate.selectors = function() {
	this.form = $('#people-update');
};

PeopleUpdate.getRow = function(input) {
	return $(input).closest('.panel');
};

PeopleUpdate.buildSelector = function(key, tag) {
	if(typeof key !== 'string') {
		key = key.join('\\]\\[');
	}
	return tag + '[name$="\\[' + key + '\\]"]';
};

PeopleUpdate.bindings = function() {
	var that = this;

	//We need to find
	that.form.find('input, select').not('[name$="[update]"]').on('change keyup', function() {
		that.getRow(this).find('[name$="[update]"]').prop('checked', true);
	});

	//When the dates change, we want to toggle whether or not the row submits
	that.form.on('keyup', that.buildSelector(['people_sco', 'date_end'], 'input'), function() {
		var rows = that.getRow(this),
			val = $(this).val(),
			inputs = rows.find(that.buildSelector(['people_sco_new', 'sco_id'], 'select') + ',' + that.buildSelector(['people_sco_new', 'date_start'], 'input')),
			test = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(val);
		inputs.prop('disabled', !test).prop('required', !!test);
	});

	//Double-check for the agency end date
	that.form.on('blur', that.buildSelector(['people_agency', 'date_end'], 'input'), function() {
		if($(this).val().length) {
			if(!confirm("Please confirm whether this person is leaving employment services.  If they aren't, please leave the support end date blank.")) {
				$(this).val('');
			}
		}
	});

	//Let's submit!
	that.form.on('submit', async function(e) {
		e.preventDefault();
		const checked = $('#people-update :checkbox:checked').closest('.panel');
		const data = checked.find(':input').serializeArray();

		data.push({name: 'model', value: 'People'});

		if (!checked.length) {
			alert("No rows were selected to be submitted!  Please check the 'update row' checkbox on the rows you wish to update.");
			return;
		}

		try {
			await ajax({
				action: 'manage_multiple',
				data,
			});
			alert("Your changes were saved successfully!");
			window.location = config.url.base + 'people-update';
		} catch { false; }
	});
};

export default PeopleUpdate;
