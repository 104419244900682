var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "panel panel-primary" }, [
    _c("div", { staticClass: "panel-heading clearfix" }, [
      _c("h3", { staticClass: "panel-title pull-left form-control-static" }, [
        _vm._v(
          "\n\t\t\t" +
            _vm._s(_vm.person.last_name) +
            ", " +
            _vm._s(_vm.person.first_name) +
            " (" +
            _vm._s(_vm.person.people_id) +
            ")\n\t\t"
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pull-right" },
        [
          !_vm.isSingle
            ? _c("verify-support", {
                attrs: {
                  person: _vm.person,
                  date: _vm.date,
                  imported: _vm.imported
                },
                on: { result: _vm.updateSupportStatus }
              })
            : _vm._e()
        ],
        1
      )
    ]),
    _vm._v(" "),
    _vm.supportStatus !== "unsupported"
      ? _c(
          "div",
          { staticClass: "list-group" },
          [
            _c("entry-header"),
            _vm._v(" "),
            _vm._l(_vm.jobs, function(job) {
              return _c("entry-row", {
                key: job.id,
                attrs: {
                  person: _vm.person,
                  job: job,
                  date: _vm.date,
                  agency: _vm.agency,
                  entry: _vm.rowEntry(job.id),
                  data_months: _vm.data_months
                }
              })
            }),
            _vm._v(" "),
            !_vm.isSingle
              ? _c("entry-row-unemployed", {
                  attrs: {
                    person: _vm.person,
                    agency: _vm.agency,
                    entry: _vm.rowEntry(0),
                    date: _vm.date
                  },
                  on: { added: _vm.addJob }
                })
              : _vm._e()
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }