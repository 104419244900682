var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "panel panel-default" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "form",
      { staticClass: "form-horizontal", on: { submit: _vm.handleSubmit } },
      [
        _c(
          "div",
          { staticClass: "panel-body" },
          _vm._l(_vm.values, function(value) {
            return _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  staticClass: "col-sm-3 control-label",
                  attrs: { for: "choose-header-" + value.key }
                },
                [_vm._v("\n\t\t\t\t\t" + _vm._s(value.name) + ":\n\t\t\t\t")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col col-sm-4" }, [
                _c(
                  "select",
                  {
                    ref: value.key,
                    refInFor: true,
                    staticClass: "form-control",
                    attrs: {
                      id: "choose-header-" + value.key,
                      name: value.key,
                      required: ""
                    }
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v("(choose a value)")
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.headers, function(header) {
                      return _c(
                        "option",
                        {
                          domProps: {
                            value: header,
                            selected:
                              _vm.getDefaultEntry(value) ===
                              header.toLowerCase()
                          }
                        },
                        [_vm._v(_vm._s(header))]
                      )
                    })
                  ],
                  2
                )
              ])
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c("import-footer", {
          on: {
            back: function($event) {
              return _vm.$emit("back")
            },
            saveDraft: function($event) {
              _vm.$emit("saveDraft", _vm.getData())
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-heading" }, [
      _c("strong", [
        _vm._v("Match columns from the CSV file to imported values.")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }