var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "panel panel-default" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "panel-body" }, [
      _c("p", [
        _vm._v("Added "),
        _c("strong", [_vm._v(_vm._s(_vm.results.new))]),
        _vm._v(" new people.")
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v("Updated "),
        _c("strong", [_vm._v(_vm._s(_vm.results.updated))]),
        _vm._v(" existing people.")
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v("No changes to "),
        _c("strong", [_vm._v(_vm._s(_vm.results.unchanged))]),
        _vm._v(" existing people.")
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v("Skipped "),
        _c("strong", [_vm._v(_vm._s(_vm.results.skipped))]),
        _vm._v(" rows.")
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v("Skipped "),
        _c("strong", [_vm._v(_vm._s(_vm.results.invalid))]),
        _vm._v(" rows because of missing or invalid data.")
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-heading" }, [
      _c("strong", [_vm._v("Results from your import")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }