import config from './config.js';
import _ from 'lodash';
import $ from './jquery.js';

async function ajax({ action, data = {}, button = null }) {
	data = processData(data);

	if (button === null) {
		button = $('.btn[type="submit"]');
	}
	else if(!button.is('.btn')) {
		button = button.find('.btn[type="submit"]');
	}

	const headers = new Headers();

	headers.append('X_REQUESTED_WITH', 'XMLHttpRequest');

	if (!(data instanceof FormData)) {
		headers.append('Content-type', 'application/json');
		headers.append('Accept', 'application/json');
		data = JSON.stringify(data);
	}

	const settings = {
		method: 'POST',
		headers,
		body: data,
	}

	let request;
	let body;

	if (button) {
		button.each(function() {
			if (!$(this).data('loadingText')) {
				$(this).data('loadingText', 'Please wait...')
			}
		});

		button.button('loading');
	}

	try {
		request = await fetch(config.url.base + action + '?_framework_ajax=1', settings)
		body = await request.json();
	} catch {
		if (button) {
			button.button('reset');
		}

		alert("There was an error; please try again later.");

		throw "There was an error; please try again later.";
	}

	if (button) {
		button.button('reset');
	}

	processMessages(body);
	processMysqlLogs(body);

	if (!body.success) {
		if (!body.auth) {
			alert(body.error);

			throw body.error;
		}
	}

	return body;
}

function processMessages({ messages }) {
	if(typeof messages !== 'undefined' && messages !== null) {
		if(typeof messages === 'object') {
			messages = messages.join('\n');
		}
		if(messages.length) {
			alert(messages);
		}
	}
}

function processMysqlLogs({ MySQL_logs }) {
	if(typeof MySQL_logs !== 'undefined' && typeof window.console !== 'undefined') {
		/*if(typeof console !== 'undefined' && typeof console.table === 'function') {
			for(var i in MySQL_logs)
				MySQL_logs[i] = {query: MySQL_logs[i].replace(/[ \t]+/g, ' ')};
			console.table(MySQL_logs);
		} else*/
		_.each(MySQL_logs, function(x) {
			if(!x.match(/SHOW COLUMNS/)) {
				window.console.log(x);
			}
		});
	}
}

function processData(data) {
	if(data instanceof FormData) {
		data.append('_inputID', config._inputID);
		data.append('_route', config.route);
	} else {
		if (_.isArray(data)) {
			const newData = {};

			for (const value of data) {
				if (value.name.endsWith('[]')) {
					const arrayName = value.name.replace('[]', '');

					if (!_.has(newData, arrayName)) {
						_.setWith(newData, arrayName, [], Object);
					}

					const arrayValue = _.get(newData, arrayName);
					arrayValue.push(value.value);

					_.setWith(newData, arrayName, arrayValue, Object);
				} else {
					_.setWith(newData, value.name, value.value, Object);
				}
			}

			data = newData;
		}

		data._inputID = config._inputID;
		data._route = config.route;
	}

	return data;
}

export default ajax;
export { processData };
