var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: "list-group-item " + (!_vm.isActiveMonth() ? "disabled" : "") },
    [
      _c("div", { staticClass: "row row-gutter-sm" }, [
        _c("div", { staticClass: "col col-sm-10" }, [
          _c(
            "div",
            { staticClass: "row row-gutter-sm" },
            [
              _c("div", { staticClass: "col col-sm-5" }, [
                _c("div", { staticClass: "form-control-static" }, [
                  _c("strong", [_vm._v(_vm._s(_vm.job.employer.name))]),
                  _c("br"),
                  _vm._v(
                    "\n\t\t\t\t\t\t" +
                      _vm._s(_vm.job.employer_location.address_full) +
                      "\n\t\t\t\t\t"
                  )
                ])
              ]),
              _vm._v(" "),
              !_vm.terminated
                ? [
                    _c("div", { staticClass: "col col-sm-3" }, [
                      _c("div", { class: _vm.fieldClass("job_type_id") }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t" +
                            _vm._s(_vm.job_type.code) +
                            " – " +
                            _vm._s(_vm.job_type.name) +
                            "\n\t\t\t\t\t\t"
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.legacy()
                      ? [
                          _c("div", { staticClass: "col col-sm-2" }, [
                            _c("div", { class: _vm.fieldClass("hours") }, [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.hoursValue()) +
                                  "\n\t\t\t\t\t\t\t"
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col col-sm-2" }, [
                            _c("div", { class: _vm.fieldClass("earnings") }, [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.earningsValue())
                              ),
                              _c("br"),
                              _vm._v(" "),
                              _vm.job.self_employed
                                ? _c("small", [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t(self-employed)\n\t\t\t\t\t\t\t\t"
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ])
                        ]
                      : [
                          _c("div", { staticClass: "col col-sm-2" }, [
                            _c("div", { class: _vm.fieldClass("hours_week") }, [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.hoursWeekValue()) +
                                  "\n\t\t\t\t\t\t\t"
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col col-sm-2" }, [
                            _c(
                              "div",
                              { class: _vm.fieldClass("hourly_rate") },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(_vm.hourlyRateValue())
                                ),
                                _c("br"),
                                _vm._v(" "),
                                _c(
                                  "small",
                                  { class: _vm.fieldClass("flag_tipped", "") },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t(" +
                                        _vm._s(_vm.tippedValue()) +
                                        ")\n\t\t\t\t\t\t\t\t"
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        ]
                  ]
                : _c("div", { staticClass: "col col-sm-7" }, [
                    _c("div", { staticClass: "form-control-static" }, [
                      _c("strong", { staticClass: "text-danger" }, [
                        _vm._v(
                          "Job ended on " +
                            _vm._s(_vm.terminated.date_end) +
                            " with code " +
                            _vm._s(_vm.termination_code.code) +
                            " – " +
                            _vm._s(_vm.termination_code.description)
                        )
                      ])
                    ])
                  ])
            ],
            2
          ),
          _vm._v(" "),
          _vm.status !== "submitted" && _vm.isActiveMonth()
            ? _c("div", { staticClass: "row row-gutter-sm" }, [
                _c(
                  "div",
                  { staticClass: "col col-sm-5" },
                  [
                    _c("verify-job", {
                      attrs: {
                        person: _vm.person,
                        job: _vm.job,
                        date: _vm.date
                      },
                      on: { result: _vm.verifyJob }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                !_vm.terminated
                  ? _c("div", { staticClass: "col col-sm-7 text-center" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-default",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.openModal()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\tUpdate job type, location, hours & earnings\n\t\t\t\t\t"
                          )
                        ]
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col col-sm-2" },
          [
            _vm.status === "submitted"
              ? _c(
                  "div",
                  { staticClass: "form-control-static" },
                  [
                    _vm.terminated
                      ? _c("em", { staticClass: "text-danger" }, [
                          _c("strong", [_vm._v("Job terminated!")])
                        ])
                      : [
                          _vm._m(0),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              class: "btn btn-primary btn-block btn-sm",
                              attrs: {
                                type: "button",
                                disabled: !_vm.canSubmit()
                              },
                              on: {
                                click: function($event) {
                                  return _vm.openModal()
                                }
                              }
                            },
                            [_vm._v("\n\t\t\t\t\t\tEdit entry\n\t\t\t\t\t")]
                          )
                        ]
                  ],
                  2
                )
              : [
                  _c(
                    "button",
                    {
                      ref: "button",
                      class:
                        "btn btn-" +
                        (_vm.terminated ? "danger" : "primary") +
                        " btn-lg",
                      attrs: { type: "button", disabled: !_vm.canSubmit() },
                      on: { click: _vm.submit }
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(
                            _vm.terminated ? "Terminate Job" : "Submit Entry"
                          ) +
                          "\n\t\t\t\t"
                      )
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.canSubmit() && _vm.isActiveMonth()
                    ? _c("div", { staticClass: "form-control-static" }, [
                        _c("em", [_vm._v("Please update missing values.")])
                      ])
                    : _vm._e()
                ]
          ],
          2
        ),
        _vm._v(" "),
        _vm.row && _vm.row.comments
          ? _c("div", { staticClass: "col col-sm-12" }, [
              _c("p", [
                _c("strong", [_vm._v("Comments:")]),
                _vm._v(" " + _vm._s(_vm.row.comments))
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.requiresComment.required && _vm.status !== "submitted"
        ? [
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "row row-gutter-sm" }, [
              _c("div", { staticClass: "col col-sm-6" }, [
                _c("div", { staticClass: "alert alert-warning" }, [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(_vm.requiresComment.message) +
                      "\n\t\t\t\t"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col col-sm-6" }, [
                _c("textarea", {
                  ref: "comments",
                  staticClass: "form-control",
                  attrs: {
                    name: "comment",
                    rows: "4",
                    placeholder: "Comments..."
                  }
                })
              ])
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      !_vm.isActiveMonth() ? [_c("br"), _vm._v(" "), _vm._m(1)] : _vm._e(),
      _vm._v(" "),
      _c(
        "form-modal",
        _vm._b(
          { ref: "modal", on: { change: _vm.modalChange, submit: _vm.update } },
          "form-modal",
          _vm.modal,
          false
        )
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("em", [_c("strong", [_vm._v("Entry submitted!")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row row-gutter-sm" }, [
      _c("div", { staticClass: "col col-sm-12" }, [
        _c("div", { staticClass: "alert alert-info" }, [
          _c("em", [
            _vm._v(
              "This is a seasonal job; this individual does not work during this month."
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }