import $ from '../jquery.js';
import ajax from '../ajax.js';

export default {
	init() {
		const agencyView = $("#agency-view-helper");

		if(agencyView.length) {
			agencyView.find('a').click(async function(e) {
				e.preventDefault();

				const agency = $(this).data('agency_id');

				try {
					await ajax({
						action: 'alt_view',
						data: { agency_id: agency, clear: true },
					});
					window.location.reload();
				} catch { false; }
			});
		}
	},
};
