import $ from '../jquery.js';
import DataTablesBase from '../datatables.js';
import ajax from '../ajax.js';

var Agencies = new DataTablesBase();

Agencies.single = false;

Agencies.dialog = {};
Agencies.dialog.agenciesAvailable = $("#agency-add-dialog-list-available");
Agencies.dialog.agenciesSelected = $("#agency-add-dialog-list-selected");
Agencies.dialog.aeView = $("#agency-add-dialog-ae-view");
Agencies.dialog.list = $("#agency-add-dialog-list");
Agencies.dialog.form = $("#agency-add-dialog-form");
Agencies.dialog.main = $("#agency-add-dialog-main");

Agencies.bindings = function() {
	var that = this;

	that.dialog.agenciesAvailable.on('click', 'a', async function(e, noAjax) {
		e.preventDefault();
		var parent = $(this).parent();
		parent.appendTo(that.dialog.agenciesSelected.find('ul'));
		if(!noAjax) {
			try {
				await ajax({
					action: 'agencyaes',
					data: {agency_id: parent.data('agency'), act: 'add'},
				});
				Agencies.table.fnDraw();
			} catch { false; }
		}
	});

	that.dialog.agenciesSelected.on('click', 'a', async function(e, noAjax) {
		e.preventDefault();
		var parent = $(this).parent();
		parent.appendTo(that.dialog.agenciesAvailable.find('ul'));
		if(!noAjax) {
			try {
				await ajax({
					action: 'agencyaes',
					data: {agency_id: parent.data('agency'), act: 'remove'},
				});
				Agencies.table.fnDraw();
			} catch { false; }
		}
	});

	that.modal.on('hidden.bs.modal.reset', function() {
		that.dialog.list.removeClass('hide');
		that.dialog.form.addClass('hide')[0].reset();
	});

	$('#agency-add-dialog-form-show').click(function() {
		that.dialog.list.addClass('hide');
		that.dialog.form.removeClass('hide').focusFirst();
	});

	if(that.dialog.aeView.length) {
		that.dialog.aeView.find('a[data-ae_id]').click(async function(e) {
			e.preventDefault();

			var ae = $(this).data('ae_id');

			try {
				await ajax({
					action: 'alt_view',
					data: { ae_id: ae, clear: true },
				});
				that.dialog.form.find('[name="ae_id"]').val(ae);
			} catch { return; }

			try {
				const res = await ajax({
					action: 'agencyaes',
					data: {ae_id: ae},
				});

				that.aeViewCallback();
				that.setAgencies(res.agencyaes);
				that.modal.on('hide.bs.modal.reload', function(e) {
					e.preventDefault();
					window.location.reload();
				});
			} catch { false; }
		});
	}
};

Agencies.aeViewCallback = function() {
	this.dialog.aeView.remove();
	this.dialog.main.removeClass('hide');
};

Agencies.setAgencies = function(agencies) {
	this.dialog.agenciesSelected.find('li').appendTo(this.dialog.agenciesAvailable.find('ul'));
	var i;
	if(typeof agencies !== 'undefined' && $.isArray(agencies)) {
		for(i in agencies) {
			this.dialog.agenciesAvailable.find('[data-agency=' + agencies[i] +'] a').trigger('click', true);
		}
	}
};

export default Agencies;