<template>
	<div class="panel panel-primary">
		<div class="panel-heading clearfix">
			<h3 class="panel-title pull-left form-control-static">
				{{ person.last_name }}, {{ person.first_name }}
			</h3>
			<div class="pull-right form-control-static">
				{{ peopleRemaining }} {{ peopleRemaining === 1 ? 'person' : 'people' }} remaining
			</div>
		</div>

		<div class="panel-body">
			<div class="row">
				<div class="col col-sm-6 col-md-4 col-lg-3">
					<div class="panel panel-default">
						<div class="panel-body">
							<p>
								<strong>Supported by {{ agency.name }} since {{ formatDate(people_agency.date_start) }}</strong>
							</p>

							<verify-date-verified :date="people_agency.date_verify"></verify-date-verified>

							<verify-support
								:person="person"
								:date="date"
								@result="updateSupportStatus"></verify-support>
						</div>
					</div>
				</div>

				<div class="col col-sm-6 col-md-4 col-lg-3" v-for="job in person.jobs" :key="job.id">
					<div class="panel panel-default">
						<div class="panel-body">
							<p>
								<strong>Employed by {{ job.employer.name }} ({{ job.employer_location.address_full }}) since {{ formatDate(job.date_start) }}</strong>
							</p>

							<verify-date-verified :date="job.date_verify"></verify-date-verified>

							<verify-job
								:person="person"
								:date="date"
								:job="job"
								@result="updateJobStatus"></verify-job>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="panel-footer text-right">
			<button type="button" class="btn btn-primary" @click="complete">Skip this person</button>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';

	export default {
		props: [ 'person', 'agency', 'date', 'peopleRemaining' ],

		data() {
			return {
				people_agency: this.person._people_agencies,
				remaining: this.initialCount(),
			}
		},

		methods: {
			updateSupportStatus(status, data) {
				if (status === 'unsupported') {
					this.complete();
				} else {
					this.update();
				}
			},

			updateJobStatus(status, data) {
				this.update();
			},

			update() {
				this.remaining --;

				if (this.remaining === 0) {
					this.complete();
				}
			},

			initialCount() {
				let count = 0;

				if (!this.person._people_agencies.date_verify || this.person._people_agencies.date_verify < this.date) {
					count += 1;
				}

				for (const job of this.person.jobs) {
					if (!job.date_verify || job.date_verify < this.date) {
						count += 1;
					}
				}

				return count;
			},

			complete() {
				this.remaining = 0;
				this.$emit('complete');
			},

			formatDate(date) {
				return moment(date).format('MMMM Do, YYYY');
			},
		},
	}
</script>
