<template>
	<div class="panel panel-default">
		<div class="panel-heading">
			<strong>Match {{ config.text.plural }} from the report with {{ config.text.plural }} in the system</strong>
		</div>
		<form class="form-horizontal" @submit="handleSubmit">
			<div class="panel-body">
				<div class="row">
					<div class="col-sm-6 col-md-4 text-right">
						<strong>Imported {{ config.text.singular }} from file</strong>
					</div>
					<div class="col-sm-6 col-md-4">
						<strong>Existing {{ config.text.singular }} in EmployStat</strong>
					</div>
				</div>
				<hr>
				<div class="form-group" v-for="(match, index) in matches">
					<label class="col-sm-6 col-md-4 control-label" :for="'match-' + index">
						{{ match }}:
					</label>
					<div class="col col-sm-6 col-md-4">
						<select :ref="'match' + index" :id="'match-' + index" :name="index" required  class="form-control">
							<option value="-1">(skip this {{ config.text.singular }})</option>
							<option
								v-for="matchValue in config.match_values"
								:value="matchValue.id"
								:selected="getDefaultEntry(match) === +matchValue.id">
								{{ matchValue[ config.name_column ] }}
							</option>
						</select>
					</div>
				</div>
			</div>
			<import-footer
				@back="$emit('back')"
				@saveDraft="$emit('saveDraft', getData())"></import-footer>
		</form>
	</div>
</template>

<script>
	import lodash from 'lodash';

	export default {
		props: [ 'matches', 'config', 'settings' ],

		computed: {
			defaultMatches() {
				if (this.settings.matches) {
					return this.settings.matches;
				}

				const matches = [];

				for (const match of this.matches) {
					for (const matchValue of this.config.match_values) {
						if (this.compareMatches(match, matchValue[ this.config.name_column ])) {
							matches.push({
								from: match,
								to: +matchValue.id,
							});
						}
					}
				}

				return matches;
			},
		},

		methods: {
			getDefaultEntry(match) {
				const search = this.defaultMatches.find(({ from }) => from === match);

				return search ? +search.to : null;
			},

			getData() {
				const matches = [];

				for (const index in this.matches) {
					const match = this.matches[ index ];
					const { options, selectedIndex } = this.$refs[`match${index}`][0];

					matches.push({
						from: match,
						to: +options[ selectedIndex ].value,
					});
				}

				return matches;
			},

			setData(matches) {
				for (const match of matches) {
					this.$refs[ match.to ][0].value = match.from;
				}
			},

			handleSubmit(e) {
				e.preventDefault();

				const matches = this.getData();

				this.$emit('complete', matches);
			},

			compareMatches(a, b) {
				a = (a || "").toLowerCase().replace(/[^a-z0-9 ]/ig, '');
				b = (b || "").toLowerCase().replace(/[^a-z0-9 ]/ig, '');

				if (a === b) {
					return true;
				}

				if (this.buildRegex(a).test(b)) {
					return true;
				}

				if (this.buildRegex(b).test(a)) {
					return true;
				}

				return false;
			},

			buildRegex(string) {
				return new RegExp(string.replace(/ /g, '.*'));
			},
		},
	}
</script>