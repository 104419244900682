var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "panel panel-primary" }, [
    _c("div", { staticClass: "panel-heading clearfix" }, [
      _c("h3", { staticClass: "panel-title pull-left form-control-static" }, [
        _vm._v(
          "\n\t\t\t" +
            _vm._s(_vm.person.last_name) +
            ", " +
            _vm._s(_vm.person.first_name) +
            "\n\t\t"
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "pull-right form-control-static" }, [
        _vm._v(
          "\n\t\t\t" +
            _vm._s(_vm.peopleRemaining) +
            " " +
            _vm._s(_vm.peopleRemaining === 1 ? "person" : "people") +
            " remaining\n\t\t"
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "panel-body" }, [
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("div", { staticClass: "col col-sm-6 col-md-4 col-lg-3" }, [
            _c("div", { staticClass: "panel panel-default" }, [
              _c(
                "div",
                { staticClass: "panel-body" },
                [
                  _c("p", [
                    _c("strong", [
                      _vm._v(
                        "Supported by " +
                          _vm._s(_vm.agency.name) +
                          " since " +
                          _vm._s(_vm.formatDate(_vm.people_agency.date_start))
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("verify-date-verified", {
                    attrs: { date: _vm.people_agency.date_verify }
                  }),
                  _vm._v(" "),
                  _c("verify-support", {
                    attrs: { person: _vm.person, date: _vm.date },
                    on: { result: _vm.updateSupportStatus }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _vm._l(_vm.person.jobs, function(job) {
            return _c(
              "div",
              { key: job.id, staticClass: "col col-sm-6 col-md-4 col-lg-3" },
              [
                _c("div", { staticClass: "panel panel-default" }, [
                  _c(
                    "div",
                    { staticClass: "panel-body" },
                    [
                      _c("p", [
                        _c("strong", [
                          _vm._v(
                            "Employed by " +
                              _vm._s(job.employer.name) +
                              " (" +
                              _vm._s(job.employer_location.address_full) +
                              ") since " +
                              _vm._s(_vm.formatDate(job.date_start))
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("verify-date-verified", {
                        attrs: { date: job.date_verify }
                      }),
                      _vm._v(" "),
                      _c("verify-job", {
                        attrs: { person: _vm.person, date: _vm.date, job: job },
                        on: { result: _vm.updateJobStatus }
                      })
                    ],
                    1
                  )
                ])
              ]
            )
          })
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "panel-footer text-right" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { type: "button" },
          on: { click: _vm.complete }
        },
        [_vm._v("Skip this person")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }