<template>
	<div class="list-group-item">
		<div class="row row-gutter-sm">
			<div class="col col-sm-7">
				<div class="form-control-static">
					<em v-if="row">{{ person.last_name }}, {{ person.first_name }} was unemployed this month.</em>
					<em v-else-if="!hasActiveJobs()">{{ person.last_name }}, {{ person.first_name }} is currently unemployed.</em>
					<em v-else>{{ person.last_name }}, {{ person.first_name }} is currently employed, though you may also add another job.</em>
				</div>
			</div>
			<div class="col col-sm-3">
				<button
					class="btn btn-primary btn-lg"
					type="button"
					@click="submitUnemployedDataEntry"
					ref="button"
					v-if="!hasActiveJobs() && !row">
					Confirm Unemployment
				</button>
			</div>
			<div class="col col-sm-2">
				<button class="btn btn-primary btn-lg" type="button" @click="openModal">
					Add New Job
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	import $ from '../../../jquery.js';
	import ajax from '../../../ajax.js';

	export default {
		props: [ 'person', 'date', 'agency', 'entry' ],

		data() {
			return {
				modal: $('#jobs-dialog'),
				form: $('#jobs-dialog form'),
				row: this.entry,
			};
		},

		methods: {
			openModal() {
				this.modal.find('[name="people_id"]').val(this.person.id);
				this.modal.modal('show');

				this.form.off('submit').on('submit', async e => {
					e.preventDefault();

					const data = this.form.serializeJSON();
					data['return'] = 'data';

					const res = await ajax({
						action: 'manage',
						data,
					});

					this.modal.forceHide();
					this.$emit('added', res.data);
				});
			},

			async submitUnemployedDataEntry() {
				const data = this.buildDataEntry();

				const res = await ajax({
					action: 'manage',
					data,
					button: $(this.$refs.button),
				});

				this.row = data;

				return res;
			},

			hasActiveJobs() {
				if (!this.person.jobs.length) {
					return false;
				}

				return !!this.person.jobs.find(job => job.is_active_month);
			},

			buildDataEntry() {
				return {
					model: 'EmploymentData',
					job_id: 0,
					people_id: this.person.id,
					date: this.date,
					agency_id: this.agency.id,
					type: this.agency.reporting_frequency,
					people_ae_id: this.person._people_aes.id,
				};
			},
		},
	}
</script>
