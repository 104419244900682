<template>
	<modal ref="modal" @submit="submit"
		:title="title"
		:form="true"
		:horizontal="true">
		<form-modal-row
			v-for="input in inputs"
			v-if="toggleRow(input)"
			@change="change"
			:input="input"
			:key="input.property"
			:ref="input.property">
			<div class="col-sm-5 pull-right small" v-if="input.property === 'job_type_id'">
				If you're unsure on which job type to select,
				<a href="https://www.onetonline.org/find/family" rel="noreferrer noopener nofollow" target="_blank">please consult O*NET's information on Job Families and Occupations</a>.
			</div>
		</form-modal-row>
	</modal>
</template>

<script>
	export default {
		props: [ 'inputs', 'title' ],

		data() {
			return {
				formData: {},
			}
		},

		methods: {
			submit(data) {
				this.$emit('submit', data);
			},

			show() {
				this.$refs.modal.show();
			},

			hide() {
				this.$refs.modal.hide();
			},

			change(data) {
				this.formData = this.$refs.modal.serialize();
				this.$emit('change', data);
			},

			toggleRow(input) {
				if (!input.show) {
					return true;
				}

				return input.show(this.formData);
			},
		},

		mounted() {
			this.formData = this.$refs.modal.serialize();
		},
	}
</script>