import Vue from 'vue';
import { parse as parseCsv } from 'csv-parse/sync';
import moment from 'moment';
import lodash from 'lodash';
import components from './dataImport/components.js';

const DataImporter = {
	data: {},
	app: null,

	init() {
		const systemMatches = window.matches;
		const config = window.importConfig;

		this.loadComponents();

		this.app = new Vue({
			el: '#data-import',

			data() {
				return {
					csvData: [],
					combinedData: [],
					finalData: [],
					settings: {
						data: [],
						headers: null,
						matches: null,
						excluded: null,
						data_import_draft_id: null,
						import_type: null,
					},
					draft: null,
					drafts: window.drafts,
					types: Object.keys(config),
				};
			},

			computed: {
				config() {
					const type = this.settings.import_type;

					if (!type) {
						return {};
					}

					const importConfig = config[ type ];

					importConfig.match_values = lodash.sortBy(systemMatches[ type ], importConfig.name_column);

					return importConfig;
				},
			},

			methods: {
				hasColumn(column) {
					if (!this.config.headers) {
						return false;
					}

					return lodash(this.config.headers).map('key').includes(column);
				},

				back(step) {
					this.settings.excluded = null;

					if (step === 'file') {
						this.settings.headers = null;
					}

					if (step === 'headers') {
						this.settings.matches = null;
					}

					// if (step === 'match') {

					// }
				},

				loadData(result) {
					this.settings.import_type = result.import_type;

					if (result.type === 'file') {
						const data = parseCsv(result.data, {
							columns: true,
							skip_empty_lines: true,
						});

						this.settings.data = data;
					} else if (result.type === 'draft') {
						this.draft = result.data;
						this.settings.data = this.draft.data;
						this.settings.headers = this.draft.headers;
						this.settings.matches = this.draft.matches || this.draft.agencies;
						this.settings.excluded = this.draft.excluded;
						this.settings.data_import_draft_id = this.draft.data_import_draft_id;
					}

					this.csvData = this.settings.data;
				},

				sortData() {
					this.data = lodash
						.sortBy(this.data, [ 'invalid', 'match', 'last_name', 'first_name' ])
				},

				setHeaders(headers) {
					const data = [];
					const current = moment();

					this.settings.headers = headers;

					for (const row of this.csvData) {
						const result = {};

						for (const { from, to } of headers) {
							result[ to ] = (row[ from ] || "").trim();
						}

						if (this.hasColumn('dob')) {
							result.dob = moment(result.dob, 'MM/DD/YY');

							if (result.dob.isAfter(current)) {
								result.dob = result.dob.subtract(100, 'y');
							}

							result.dob = result.dob.format('YYYY-MM-DD');

							if (result.dob === "Invalid date") {
								result.dob = "";
							}
						}

						result.first_name = lodash.startCase(lodash.toLower(result.first_name));
						result.last_name = lodash.startCase(lodash.toLower(result.last_name));

						result.invalid = !result.first_name ||
							!result.last_name ||
							!result[ this.config.match_column ] ||
							!/^[0-9]{9}$/.test(result.mci);

						result.included = !result.invalid;

						if (result.invalid) {
							result.notes = 'Missing/invalid data, skipping.';
							result.className = 'danger';
							result.status = 'invalid';
						} else {
							result.notes = '';
							result.className = '';
							result.status = '';
						}

						data.push(result);
					}

					this.combinedData = data;

					this.sortData();

					this.combinedData = this.combinedData.map((row, index) => ({
						... row,
						... { index },
					}));
				},

				setMatch(matches) {
					this.settings.matches = matches;

					this.finalData = this.combinedData.map(row => {
						if (row.invalid) {
							return row;
						}

						const mapping = lodash.find(
							matches,
							[ 'from', row[ this.config.match_column ] ]);

						const matchValue = lodash.find(
							this.config.match_values,
							[ this.config.match_id, mapping.to.toString() ]);

						// if (!mapping || !matchValue) {
						// 	console.log(
						// 		'row', { ... row },
						// 		'mapping', { ... mapping },
						// 		'matchValue', { ... matchValue });
						// }

						if (!mapping || mapping.to === -1) {
							row.invalid = true;
							row.included = false;
							row[ this.config.match_column ] = `${row[ this.config.match_column ]} (skipped)`;
							row.notes = `Skipping this ${this.config.text.singular}`;
							row.status = 'skipped';
						} else {
							row[ this.config.match_id ] = mapping.to;
							row[ this.config.match_column ] = matchValue[ this.config.name_column ];
						}

						return row;
					});

					this.sortData();
				},

				chooseRows(excluded) {
					this.settings.excluded = excluded;

					this.finalData = this.finalData.map(row => {
						row.included = !excluded.includes(row.index);

						return row;
					});
				},

				addNotes(results) {
					this.finalData = this.finalData.map(row => {
						if (row.index in results) {
							const result = results[ row.index ];

							row.notes = result.notes;
							row.status = result.status;
						}

						return row;
					})
				},
			},
		});
	},

	loadComponents() {
		for (const component of Object.keys(components)) {
			Vue.component(component, components[ component ]);
		}
	},
}

export default DataImporter;
