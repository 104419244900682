import components from '../../components.js';

import dataEntry from './components/data-entry.vue';
import multiple from './components/multiple.vue';
import group from './components/group.vue';
import tab from './components/tab.vue';
import header from './components/header.vue';
import row from './components/row.vue';
import rowUnemployed from './components/row-unemployed.vue';
import verifySupport from '../peopleVerify/components/support.vue';
import verifyJob from '../peopleVerify/components/job.vue';
import filterNoResults from './components/filter-no-results.vue';

export default {
	... components,

	"data-entry": dataEntry,
	"entry-multiple": multiple,
	"entry-group": group,
	"entry-tab": tab,
	"entry-header": header,
	"entry-row": row,
	"entry-row-unemployed": rowUnemployed,
	"verify-support": verifySupport,
	"verify-job": verifyJob,
	"entry-filter-no-results": filterNoResults,
};
