var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.input.hidden
    ? _c(
        "div",
        { staticClass: "form-group" },
        [
          _c(
            "label",
            { staticClass: "col-sm-4 control-label", attrs: { for: _vm.id } },
            [_vm._v("\n\t\t" + _vm._s(_vm.input.label) + ":\n\t")]
          ),
          _vm._v(" "),
          _vm.input.options
            ? _c(
                "select",
                {
                  staticClass: "form-control col-sm-3",
                  attrs: {
                    name: _vm.input.property,
                    id: _vm.id,
                    required: !!_vm.input.required
                  },
                  on: {
                    change: function($event) {
                      return _vm.change($event)
                    }
                  }
                },
                [
                  !_vm.input.noDefault
                    ? _c("option", { attrs: { value: "" } }, [
                        _vm._v("\n\t\t\t(select an option)\n\t\t")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.input.options, function(option) {
                    return _c(
                      "option",
                      { key: option.id, domProps: { value: option.id } },
                      [_vm._v("\n\t\t\t" + _vm._s(option.name) + "\n\t\t")]
                    )
                  })
                ],
                2
              )
            : _c("input", {
                staticClass: "form-control col-sm-3",
                attrs: {
                  type: "text",
                  name: _vm.input.property,
                  id: _vm.id,
                  required: !!_vm.input.required,
                  placeholder: _vm.input.placeholder
                },
                on: {
                  change: function($event) {
                    return _vm.change($event)
                  }
                }
              }),
          _vm._v(" "),
          _vm.input.current
            ? _c("small", { staticClass: "col-sm-5" }, [
                _c("strong", [_vm._v("Current:")]),
                _c("br"),
                _vm._v("\n\t\t" + _vm._s(_vm.input.current) + "\n\t")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._t("default")
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }