<template>
	<div class="verify-support">
		<div class="form-control-static" v-if="status === 'verified' || date <= person._people_agencies.date_verify">
			<strong>
				<em>Support verified on {{ dateVerified() }}.</em>
			</strong>
		</div>

		<div class="form-control-static" v-else-if="status === 'unsupported'">
			<strong>
				<em>No longer supported</em>
			</strong>
		</div>

		<div class="btn-group" v-else>
			<button ref="button" type="button" class="btn btn-success" @click="verifySupport">
				Still supported
			</button>

			<button type="button" class="btn btn-danger" @click="showModal">
				No longer supported
			</button>
		</div>

		<form-modal ref="modal" @submit="endSupport" v-bind="modal"></form-modal>
	</div>
</template>

<script>
	import ajax from '../../../ajax.js';
	import moment from 'moment';

	export default {
		props: [ 'person', 'date' ],

		data() {
			const data = {
				status: 'unverified',
				termination_codes: window.termination_codes,
				people_agency: this.person._people_agencies,
				date_verify: this.person._people_agencies.date_verify,
				modal: {
					title: `End agency support for ${this.person.last_name}, ${this.person.first_name}`,
					inputs: [
						{
							property: 'date_end',
							label: 'Support end date',
							current: '',
							placeholder: 'DD/MM/YYYY',
							required: true,
						},
					],
				},
			};

			for (const job of this.person.jobs) {
				data.modal.inputs.push({
					property: `jobs[${job.id}][terminated]`,
					label: `Job at ${job.employer.name}`,
					noDefault: true,
					options: [
						{
							id: 0,
							name: 'Still employed',
						},
						{
							id: 1,
							name: 'No longer employed',
						}
					],
				});

				data.modal.inputs.push({
					property: `jobs[${job.id}][termination_code_id]`,
					label: '… termination code',
					options: window.termination_codes
						.filter(code => !!code.code)
						.map(code => ({
							... code,
							name: `${code.code} – ${code.description}`,
						})),
					required: true,
					show(input) {
						try {
							return +input.jobs[ job.id ].terminated === 1;
						} catch {
							return false;
						}
					},
				});

				data.modal.inputs.push({
					property: `jobs[${job.id}][date_end]`,
					label: '… termination date',
					required: true,
					placeholder: 'DD/MM/YYYY',
					show(input) {
						try {
							return +input.jobs[ job.id ].terminated === 1;
						} catch {
							return false;
						}
					},
				});
			}

			return data;
		},

		methods: {
			dateVerified() {
				return moment(this.date_verify).format('MMMM Do, YYYY');
			},

			async verifySupport(e) {
				e.preventDefault();

				const data = {
					model: 'PeopleAgencies',
					PK: this.people_agency.id,
					date: this.date,
				};

				try {
					await ajax({
						action: 'verify',
						data,
						button: $(this.$refs.button),
					});

					this.status = 'verified';
					this.date_verify = this.date;
					this.$emit('result', 'verified', { date_verify: this.date });
				} catch { return; };
			},

			showModal(e) {
				e.preventDefault();

				this.$refs.modal.show();
			},

			async endSupport(input) {
				input.date_end = moment(input.date_end).format('YYYY-MM-DD');

				const data = {
					... this.people_agency,
					model: 'PeopleAgencies',
					PK: this.people_agency.id,
					date_end: input.date_end,
				};

				await ajax({
					action: 'manage',
					data,
				});

				for (const job of this.person.jobs) {
					const jobInput = input.jobs[ job.id ];

					if (!jobInput || +jobInput.terminated === 0) {
						continue;
					}

					const jobData = {
						... job,
						model: 'Jobs',
						PK: job.id,
						date_end: moment(jobInput.date_end).format('YYYY-MM-DD'),
						termination_code_id: jobInput.termination_code_id,
					}

					await ajax({
						action: 'manage',
						data: jobData,
					});
				}

				this.$refs.modal.hide();
				this.status = 'unsupported';
				this.$emit('result', 'unsupported', input);
			},
		},
	}
</script>
