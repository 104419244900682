<template>
	<entry-multiple
		v-if="isMultiple"
		:people="people"
		:entries="entries"
		:date="date"
		:agency="agency"
		:data_months="data_months"></entry-multiple>

	<div v-else-if="isSingle">
		<entry-group
			v-for="person in people"
			:person="person"
			:date="date"
			:agency="agency"
			:imported="false"
			:data_months="data_months"
			:is-single="true"
			:key="person.id"></entry-group>
	</div>
</template>

<script>
	export default {
		props: [ 'people', 'entries', 'date', 'agency', 'data_months', 'isMultiple', 'isSingle' ],
	}
</script>
