import $ from '../jquery.js';
import Vue from 'vue';
// import moment from 'moment';
// import lodash from 'lodash';
import components from './dataEntry/components.js';
import jobsDialog from '../elements/jobsAddDialog.js';
import agencyView from '../elements/agencyView.js';

const people = window.people;
const agency = window.agency;
const data_months = window.data_months;

const DataEntry = {
	data: {},
	app: null,

	init() {
		jobsDialog.init();
		agencyView.init();
		
		this.loadComponents();

		if ($('#data-entry').length) {
			this.app = new Vue({
				el: '#data-entry',

				data() {
					return {
						people,
						agency,
						data_months,
					};
				},

				methods: {

				},
			});
		}
	},

	loadComponents() {
		for (const component of Object.keys(components)) {
			Vue.component(component, components[ component ]);
		}
	},
}

export default DataEntry;
