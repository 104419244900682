<template>
	<div class="form-group" v-if="!input.hidden">
		<label class="col-sm-4 control-label" :for="id">
			{{ input.label }}:
		</label>

		<select v-if="input.options" :name="input.property" class="form-control col-sm-3" :id="id" :required="!!input.required" @change="change($event)">
			<option value="" v-if="!input.noDefault">
				(select an option)
			</option>

			<option v-for="option in input.options" :value="option.id" v-bind:key="option.id">
				{{ option.name }}
			</option>
		</select>

		<input v-else type="text" :name="input.property" class="form-control col-sm-3" :id="id" :required="!!input.required" :placeholder="input.placeholder" @change="change($event)">

		<small class="col-sm-5" v-if="input.current">
			<strong>Current:</strong><br>
			{{ input.current }}
		</small>

		<slot></slot>
	</div>
</template>

<script>
	import _ from 'lodash';

	export default {
		props: [ 'input' ],

		data() {
			return {
				id: `modal_${this.input.property.replace(/[\[]/g, '_').replace(/[\]]/g, '')}`,
			}
		},

		methods: {
			change(e) {
				const data = {};

				_.set(data, e.target.name, e.target.value);

				this.$emit('change', data);
			},
		},
	}
</script>
