<template>
	<div class="panel-footer clearfix">
		<button type="button" class="btn btn-default" @click="$emit('back')" v-if="!firstStep">
			Back
		</button>
		<button type="submit" class="btn btn-primary pull-right">
			Continue
		</button>
		<button type="button" class="btn btn-default pull-right" @click="$emit('saveDraft')" v-if="!firstStep">
			Save Draft
		</button>
	</div>
</template>

<script>
	export default {
		props: [ 'firstStep' ],
	}
</script>
