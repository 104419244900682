<template>
	<div class="panel panel-default">
		<div class="panel-heading">
			<label for="data-import-file" class="control-label">Choose a file to import.</label>
		</div>

		<form class="form" @submit.prevent="submit" method="post" action="">
			<div class="panel-body">
				<h4>Import file</h4>

				<div class="row">
					<div class="col col-sm-4">
						<input type="file" name="import_file" ref="file" class="form-control" accept="text/csv" id="data-import-file" required>
					</div>
					<div class="col col-sm-8">
						<p class="form-control-static">
							<em>File must be a CSV.</em>
						</p>
					</div>
				</div>

				<hr>
				<h4>Import type</h4>

				<label class="radio-inline">
					<input type="radio" name="import_type" value="agency" v-model="import_type">
					Agency Support
				</label>

				<label class="radio-inline">
					<input type="radio" name="import_type" value="race" v-model="import_type">
					Race
				</label>

				<label class="radio-inline">
					<input type="radio" name="import_type" value="ethnicity" v-model="import_type">
					Ethnicity
				</label>

				<template v-if="drafts">
					<hr>
					<h4>Saved Drafts</h4>
					<ul>
						<li v-for="draft in drafts">
							<a href="#" @click.prevent="setDraft(draft)">
								Draft saved on {{ date(draft) }} ({{ draft.import_type }})
							</a>
						</li>
					</ul>
				</template>
			</div>

			<import-footer :firstStep="true"></import-footer>
		</form>
	</div>
</template>

<script>
	import moment from 'moment';
	import ajax from '../../../ajax.js';

	export default {
		data() {
			return {
				import_type: 'agency',
			};
		},

		props: [ 'drafts', 'settings', 'types' ],

		methods: {
			submit() {
				const reader = new FileReader();

				reader.readAsText(this.$refs.file.files[0]);

				reader.onload = () => {
					if (!this.types.includes(this.import_type)) {
						alert('Invalid data submitted.');
						return;
					}

					this.$emit('complete', {
						type: 'file',
						data: reader.result,
						import_type: this.import_type,
					});
				};
			},

			async setDraft(draft) {
				const res = await ajax({
					action: 'dataImportLoadDraft',
					data: { data_import_draft_id: draft.id },
				});

				if (!this.types.includes(res.draft.import_type)) {
					alert('Invalid data submitted.');
					return;
				}

				this.$emit('complete', {
					type: 'draft',
					data: res.draft,
					import_type: res.draft.import_type,
				});
			},

			date(draft) {
				return moment(draft.date).format('MMMM D, YYYY [at] h:mma');
			},
		},
	}
</script>
