import Mousetrap from 'mousetrap';
import $ from '../jquery.js';

//Let's get some arrow key navigation in for tables
Mousetrap.bind(['up', 'down', 'left', 'right'], function(e) {
	var target = $(e.target), focus, move, index, row, tabbables;
	if(target.is('td') || target.is('th') || target.closest('td')) {
		e.preventDefault();
		row = target.closest('tr');
		tabbables = row.find(':tabbable');
		index = tabbables.index(target);
		switch(e.keyIdentifier) {
			case 'Up':
				move = row.prev();
				if(move.length) {
					focus = move.find(':tabbable').eq(index);
				} else {
					if(target.closest('tbody').length) {
						focus = target.closest('table').find('thead tr:last').find(':tabbable').eq(index);
					} else {
						return;
					}
				}
				break;
			case 'Down':
				move = row.next();
				if(move.length) {
					focus = move.find(':tabbable').eq(index);
				} else {
					if(target.closest('thead').length) {
						focus = target.closest('table').find('tbody tr:first').find(':tabbable').eq(index);
					} else {
						return;
					}
				}
				break;
			case 'Left':
				move = tabbables.eq(index-1);
				if(index > 0 && move.length) {
					focus = move;
				} else {
					move = row.prev();
					if(move.length) {
						focus = move.find(':tabbable').last();
					} else {
						if(target.closest('tbody').length) {
							focus = target.closest('table').find('thead tr:last :tabbable').last();
						} else {
							return;
						}
					}
				}
				break;
			case 'Right':
				move = tabbables.eq(index+1);
				if(move.length) {
					focus = move;
				} else {
					move = row.next();
					if(move.length) {
						focus = move.find(':tabbable').first();
					} else {
						if(target.closest('thead').length) {
							focus = target.closest('table').find('tbody tr:first :tabbable').first();
						} else {
							return;
						}
					}
				}
				break;
		}
		if(focus) {
			focus.focus();
		}
	}
});