<template>
	<div class="verify-job">
		<div class="form-control-static" v-if="status === 'verified' || date <= job.date_verify">
			<strong><em>Employment status verified on {{ dateVerified() }}.</em></strong>
		</div>

		<div class="form-control-static" v-else-if="status === 'terminated'">
			<strong>
				<em>Job terminated</em>
			</strong>
		</div>

		<div class="btn-group" v-else>
			<button ref="button" type="button" class="btn btn-success" @click="verifyJob">
				Still employed
			</button>
			<button type="button" class="btn btn-danger" @click="showModal">
				No longer employed
			</button>
		</div>

		<form-modal ref="modal" @submit="terminateJob" v-bind="modal"></form-modal>
	</div>
</template>

<script>
	import ajax from '../../../ajax.js';
	import moment from 'moment';

	export default {
		props: [ 'person', 'date', 'job' ],

		data() {
			return {
				status: 'unverified',
				date_verify: this.job.date_verify,
				modal: {
					title: `Terminate ${this.person.last_name}, ${this.person.first_name}'s job at ${this.job.employer.name}`,
					inputs: [
						{
							property: 'termination_code_id',
							label: 'Termination code',
							options: Object.values(window.termination_codes)
								.filter(code => !!code.code)
								.map(code => ({
									... code,
									name: `${code.code} – ${code.description}`,
								})),
							required: true,
						},
						{
							property: 'date_end',
							label: 'Termination date',
							required: true,
							placeholder: 'DD/MM/YYYY',
						},
					],
				},
			};
		},

		methods: {
			dateVerified() {
				return moment(this.date_verify).format('MMMM Do, YYYY');
			},

			async verifyJob(e) {
				e.preventDefault();

				const data = {
					model: 'Jobs',
					PK: this.job.id,
					date: this.date,
				};

				try {
					await ajax({
						action: 'verify',
						data,
						button: $(this.$refs.button),
					});
				} catch { return; }

				this.status = 'verified';
				this.date_verify = this.date;
				this.$emit('result', 'verified', { date_verify: this.date });
			},

			showModal(e) {
				e.preventDefault();

				this.$refs.modal.show();
			},

			async terminateJob(input) {
				input.date_end = moment(input.date_end).format('YYYY-MM-DD');

				const data = {
					... this.job,
					model: 'Jobs',
					PK: this.job.id,
					... input,
				};

				try {
					await ajax({
						action: 'manage',
						data,
					});
				} catch { return; }

				this.$refs.modal.hide();
				this.status = 'terminated';
				this.$emit('result', 'terminated', input);
			},
		},
	}
</script>
