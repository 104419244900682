import $ from '../jquery.js';
import initDataTables from '../elements/dataTables.js';
import ajax from '../ajax.js';

const Employer = {};

Employer.single = true;
Employer.close = false;
Employer.form = $('#employer-form');

Employer.tables = {};

Employer.init = function() {
	this.dataTables();
	this.bindings();
}

Employer.dataTables = function() {
	// Set up data tables
	for (const [ key, value ] of Object.entries(window.dataTables)) {
		const data = initDataTables(value);
		this.tables[ key ] = data.table;
	}
}

Employer.bindings = function() {
	const that = this;

	//Submit the form
	that.form.submit(async function(e) {
		e.preventDefault();

		try {
			await ajax({
				action: 'manage',
				data: new FormData(this),
			});
			alert("Data was successfully saved!");
		} catch { false; }
	});

	if(that.tables.locations.toggle) {
		that.tables.locations.toggle.click(async function(e) {
			e.preventDefault();

			if($(this).hasClass('disabled')) {
				return;
			}

			const PK = that.tables.locations.table.find('tr.success').pluck('id');

			let res;

			try {
				res = await ajax({
					action: 'merge',
					data: { model: 'EmployerLocations', PK },
				});
			} catch { return; }

			const modal = $($.parseHTML(res.html)).initModal(async function() {
				if(confirm("Are you sure you wish to merge these Employer Locations?  This is irreversible!")) {
					const data = {
						... $(this).serializeJSON(),
						submit: true,
						PK,
					}

					try {
						await ajax({
							action: 'merge',
							data,
						});
						modal.forceHide();
						that.tables.locations.api.draw();
					} catch { false; }
				}
			});
		});
	}
};

window.Employer = Employer;

export default Employer;
