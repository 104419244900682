<template>
	<div class="panel panel-primary">
		<div class="panel-heading clearfix">
			<h3 class="panel-title pull-left form-control-static">
				{{ person.last_name }}, {{ person.first_name }} ({{ person.people_id }})
			</h3>

			<div class="pull-right">
				<verify-support
					v-if="!isSingle"
					:person="person"
					:date="date"
					:imported="imported"
					@result="updateSupportStatus"></verify-support>
			</div>
		</div>

		<div class="list-group" v-if="supportStatus !== 'unsupported'">
			<entry-header></entry-header>

			<entry-row
				v-for="job in jobs"
				:person="person"
				:job="job"
				:date="date"
				:agency="agency"
				:entry="rowEntry(job.id)"
				:data_months="data_months"
				:key="job.id"></entry-row>

			<entry-row-unemployed
				v-if="!isSingle"
				@added="addJob"
				:person="person"
				:agency="agency"
				:entry="rowEntry(0)"
				:date="date"></entry-row-unemployed>
		</div>
	</div>
</template>

<script>
	export default {
		props: [ 'person', 'date', 'agency', 'imported', 'data_months', 'isSingle' ],

		data() {
			return {
				supportStatus: 'unverified',
				jobs: this.person.jobs,
			};
		},

		methods: {
			updateSupportStatus(result) {
				this.supportStatus = result;
			},

			rowEntry(job_id) {
				return this.person.entries.find(
					entry => +job_id === +entry.job_id && this.date === entry.date)
			},

			addJob(job) {
				this.jobs.push(job);
			},
		},
	}
</script>