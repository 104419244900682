import $ from '../jquery.js';
import ajax from '../ajax.js';
import config from '../config.js';
import initDataTables from '../elements/dataTables.js';
import jobsDialog from '../elements/jobsDialog.js';
import jobsAddDialog from '../elements/jobsAddDialog.js';

const Demographics = {};

window.Demographics = Demographics;

Demographics.tables = {};
Demographics.modals = {};
Demographics.toggles = {};
Demographics.close = false;

Demographics.form = $('#demographics-info form');
Demographics.tabs = $('#demographics-tabs');

Demographics.init = function() {
	jobsAddDialog.init();
	jobsDialog.init();
	this.dataTables();
	this.bindings();
};

Demographics.dataTables = function() {
	// Set up data tables
	for (const [ key, value ] of Object.entries(window.dataTables)) {
		const data = initDataTables(value);
		this.tables[ key ] = data.table;
		this.modals[ key ] = data.modal;
		this.toggles[ key ] = data.toggle;
	}

	//Extra controls on Jobs table.
	this.tables.jobs.on('click', '.btn-update', async function(e) {
		e.preventDefault();

		const row = $(this).closest('tr');
		const PK = row.data('id');

		const dialogRes = await ajax({
			action: 'dialog',
			data: {model: 'JobHistory', PK: PK},
		});

		const modal = $($.parseHTML(dialogRes.html)).initModal(async function() {
			const res = await ajax({
				action: 'manage',
				data: $(this).serializeArray(),
			})

			modal.forceHide();
			Demographics.tables.jobs.DataTable().row(row[0]).data(res.data).draw();
		});
	});

	this.tables.jobs.on('click', '.btn-history', async function(e) {
		e.preventDefault();

		const row = $(this).closest('tr');
		const PK = row.data('id');

		try {
			const res = await ajax({
				action: 'dialog',
				data: {model: 'JobHistory', element: 'jobHistoryListDialog', PK: PK},
			});
			$($.parseHTML(res.html)).initModal();
		} catch { false; }
	});
};

Demographics.bindings = function() {
	var that = this;

	//Make sure we go to the right place when we submit the form
	$('#demographics-info :submit').click(function() {
		that.close = !!$(this).data('close');
	});

	//Submit the form
	this.form.submit(async function(e) {
		e.preventDefault();

		await ajax({
			action: 'manage',
			data: that.form.serializeJSON(),
		});

		alert("Data was successfully saved!");

		if(that.close) {
			if(typeof window.opener !== 'undefined') {
				window.close();
			} else {
				window.location = config.url.base;
			}
		}
	});

	//Tab navigation - but make them normal links if no pushState available.
	$('#demographics-nav a').on({
		click: function(e, pop) {
			e.preventDefault();
			$(this).tab('show');
			if(!pop) {
				window.history.pushState({tab: $(this).data('target')}, null, this.href);
			}
		},
		shown: function() {
			that.focus();
		},
	});

	//popstate handler
	$(window).on('popstate', function(e) {
		var tab = (e.originalEvent.state !== null && typeof e.originalEvent.state.tab === 'string') ? e.originalEvent.state.tab : undefined;
		if(typeof tab === 'string') {
			$('#demographics-nav a[data-target="' + tab + '"]').trigger('click', true);
		}
	});

	if(that.toggles.jobs) {
		that.toggles.jobs.click(async function(e) {
			e.preventDefault();

			if($(this).hasClass('disabled')) {
				return;
			}

			const PK = that.tables.jobs.find('tr.success').pluck('id');

			const res = await ajax({
				action: 'merge',
				data: {model: 'Jobs', PK: PK},
			});

			$($.parseHTML(res.html)).initModal(async function() {
				if(!confirm("Are you sure you wish to merge these Jobs?  This is irreversible!")) {
					return;
				}

				await ajax({
					action: 'merge',
					data: $(this).serializeArray(),
				});

				//modal.forceHide();
				window.location.reload();
			});
		});
	}
};

Demographics.focus = function() {
	this.tabs.find('.active').focusFirst();
};

/*setTimeout(function() {
	Demographics.tables.jobs.find('tbody tr:lt(3) .btn-merge').trigger('click');
	Demographics.toggles.jobs.trigger('click');
}, 1500);*/

export default Demographics;