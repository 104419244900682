var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.step === "file"
    ? _c("file-input", {
        attrs: { drafts: _vm.drafts, settings: _vm.settings, types: _vm.types },
        on: { complete: _vm.loadFile }
      })
    : _vm.step === "headers"
    ? _c("choose-headers", {
        attrs: {
          headers: _vm.headers,
          config: _vm.config,
          settings: _vm.settings
        },
        on: {
          back: function($event) {
            return _vm.setStepBack("file")
          },
          saveDraft: _vm.saveDraft,
          complete: _vm.setHeaders
        }
      })
    : _vm.step === "match"
    ? _c("match", {
        attrs: {
          matches: _vm.matches,
          config: _vm.config,
          settings: _vm.settings
        },
        on: {
          back: function($event) {
            return _vm.setStepBack("headers")
          },
          saveDraft: _vm.saveDraft,
          complete: _vm.setMatch
        }
      })
    : _vm.step === "rows"
    ? _c("review-data", {
        attrs: {
          data: _vm.finalData,
          config: _vm.config,
          settings: _vm.settings
        },
        on: {
          back: function($event) {
            return _vm.setStepBack("match")
          },
          saveDraft: _vm.saveDraft,
          complete: _vm.submitData
        }
      })
    : _vm.step === "results"
    ? _c("results", { attrs: { results: _vm.results } })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }