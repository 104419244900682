var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isOpen
    ? _c("div", { ref: "modal", staticClass: "modal fade" }, [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c(
              "form",
              {
                ref: "form",
                class: "form" + (_vm.horizontal ? "-horizontal" : ""),
                on: { submit: _vm.submit }
              },
              [
                _c("div", { staticClass: "modal-header" }, [
                  _c("button", {
                    staticClass: "close glyphicon glyphicon-remove",
                    attrs: {
                      type: "button",
                      "data-dismiss": "modal",
                      "aria-hidden": "true"
                    }
                  }),
                  _vm._v(" "),
                  _vm.title
                    ? _c("h4", { staticClass: "modal-title" }, [
                        _vm._v(_vm._s(_vm.title))
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "modal-body" },
                  [_vm._t("default")],
                  2
                ),
                _vm._v(" "),
                _vm.form
                  ? _c("div", { staticClass: "modal-footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "submit" }
                        },
                        [_vm._v("Save and Close")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-default",
                          attrs: { type: "button", "data-dismiss": "modal" }
                        },
                        [_vm._v("Cancel")]
                      )
                    ])
                  : _vm._e()
              ]
            )
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }