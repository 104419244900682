import $ from '../jquery.js';
import DataTablesBase from '../datatables.js';
import ajax from '../ajax.js';

const Scos = new DataTablesBase();

Scos.dialog = {};
Scos.dialog.scosAvailable = $("#sco-add-dialog-list-available");
Scos.dialog.scosSelected = $("#sco-add-dialog-list-selected");
Scos.dialog.aeView = $("#sco-add-dialog-ae-view");
Scos.dialog.list = $("#sco-add-dialog-list");
Scos.dialog.form = $("#sco-add-dialog-form");
Scos.dialog.main = $("#sco-add-dialog-main");

Scos.bindings = function() {
	var that = this;

	that.dialog.scosAvailable.on('click', 'a', async function(e, noAjax) {
		e.preventDefault();

		const parent = $(this).parent();
		parent.appendTo(that.dialog.scosSelected.find('ul'));

		if (!noAjax) {
			await ajax({
				action: 'scoaes',
				data: {sco_id: parent.data('sco'), act: 'add'},
			});
			that.table.fnDraw();
		}
	});

	that.dialog.scosSelected.on('click', 'a', async function(e, noAjax) {
		e.preventDefault();

		const parent = $(this).parent();
		parent.appendTo(that.dialog.scosAvailable.find('ul'));

		if (!noAjax) {
			await ajax({
				action: 'scoaes',
				data: {sco_id: parent.data('sco'), act: 'remove'},
			});
			that.table.fnDraw();
		}
	});

	that.modal.on('hidden.bs.modal.reset', function() {
		that.dialog.list.removeClass('hide');
		that.dialog.form.addClass('hide')[0].reset();
	});

	$('#sco-add-dialog-form-show').click(function() {
		that.dialog.list.addClass('hide');
		that.dialog.form.removeClass('hide').focusFirst();
	});

	if (that.dialog.aeView.length) {
		that.dialog.aeView.find('a[data-ae_id]').click(async function(e) {
			e.preventDefault();

			const ae = $(this).data('ae_id');

			await ajax({
				action: 'alt_view',
				data: { ae_id: ae, clear: true },
			});

			that.dialog.form.find('[name="ae_id"]').val(ae);

			const res = await ajax({
				action: 'scoaes',
				data: {ae_id: ae},
			});

			that.aeViewCallback();
			that.setScos(res.scoaes);
			that.modal.on('hide.bs.modal.reload', function(e) {
				e.preventDefault();
				window.location.reload();
			});
		});
	}
};

Scos.aeViewCallback = function() {
	this.dialog.aeView.remove();
	this.dialog.main.removeClass('hide');
};

Scos.setScos = function(scos) {
	this.dialog.scosSelected.find('li').appendTo(this.dialog.scosAvailable.find('ul'));
	if (typeof scos !== 'undefined' && $.isArray(scos)) {
		for (const sco of scos) {
			this.dialog.scosAvailable.find('[data-sco=' + sco +'] a').trigger('click', true);
		}
	}
};

export default Scos;