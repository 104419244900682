var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.date
    ? _c("p", [
        _c("small", [
          _vm._v("Last verified " + _vm._s(_vm.formatDate(_vm.date)))
        ])
      ])
    : _c("p", [_vm._m(0)])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("small", [_c("em", [_vm._v("Has never been verified.")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }