<template>
	<div class="panel panel-default">
		<div class="panel-heading">
			<strong>Review the included data and choose any rows to exclude from the import.</strong>
		</div>
		<form class="form" @submit="handleSubmit">
			<div class="panel-body">
				<table class="table table-condensed">
					<thead>
						<tr>
							<th colspan="2">Name</th>
							<th>MCI</th>
							<th>DOB</th>
							<!-- <th>Status</th> -->
							<th>Notes</th>
						</tr>
					</thead>
					<tbody>
						<template v-for="group in grouped">
							<tr>
								<th colspan="5">{{ group[0] }}</th>
							</tr>
							<tr v-for="row in group[1]" :class="className(row)" @click="toggleRow($event, row)">
								<td>
									<input type="checkbox" name="rows[]" :value="row.index" :checked="defaultChecked(row)" :disabled="disabled(row)">
								</td>
								<td class="nowrap">{{ row.last_name }}, {{ row.first_name }}</td>
								<td class="nowrap">{{ row.mci }}</td>
								<td class="nowrap">{{ row.dob }}</td>
								<!-- <td>{{ row.status }}</td> -->
								<td>{{ row.notes }}</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>
			<import-footer
				@back="$emit('back')"
				@saveDraft="$emit('saveDraft', getData())"></import-footer>
		</form>
	</div>
</template>

<script>
	import lodash from 'lodash';

	export default {
		props: [ 'data', 'step', 'settings', 'config' ],

		data() {
			return {
				excluded: [],
			};
		},

		computed: {
			grouped() {
				const grouped = lodash.groupBy(this.data, this.config.match_column);
				const keys = Object.keys(grouped);
				const values = Object.values(grouped);

				return lodash.zip(keys, values);
			},
			defaultExcluded() {
				if (this.settings.excluded) {
					return this.settings.excluded;
				}

				return [];
			},
		},

		methods: {
			getData() {
				return this.excluded;
			},

			defaultChecked(row) {
				if (row.invalid) {
					return false;
				}

				if (this.defaultExcluded.includes(+row.index)) {
					return false;
				}

				if (this.excluded.includes(+row.index)) {
					return false;
				}

				return true;
			},

			handleSubmit(e) {
				e.preventDefault();

				if (!confirm("Submit the import?")) {
					return;
				}

				this.$emit('complete', this.excluded);
			},

			disabled(row) {
				return row.invalid || row.status === 'unchanged';
			},

			className(row) {
				if (row.status === 'invalid') {
					return 'danger';
				}

				if (row.status === 'skipped' || this.excluded.includes(row.index)) {
					return 'skipped';
				}

				if (row.status === 'unchanged') {
					return 'warning';
				}

				if (row.status === 'updated') {
					return 'info';
				}

				if (row.status === 'new') {
					return 'success';
				}
			},

			toggleRow(e, row) {
				const element = e.target.parentNode;
				const checkbox = element.querySelector('[type="checkbox"]');

				if (checkbox.disabled) {
					return;
				}

				if (e.target.type !== "checkbox") {
					checkbox.checked = !checkbox.checked;
				}

				if (checkbox.checked) {
					this.excluded = this.excluded.filter(value => value !== +row.index)
				} else {
					this.excluded.push(+row.index);
				}
			},
		},
	}
</script>