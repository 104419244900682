import config from './config.js';

import Agencies from './pages/agencies.js';
import Agency from './pages/agency.js';
import ContactSupport from './pages/contactSupport.js';
import Dashboard from './pages/dashboard.js';
import DataEntry from './pages/dataEntry.js';
import DataImport from './pages/dataImport.js';
import DataInaccuracies from './pages/dataInaccuracies.js';
import DataReview from './pages/dataReview.js';
import Demographics from './pages/demographics.js';
import Discussion from './pages/discussion.js';
import EmailUsers from './pages/emailUsers.js';
import Employer from './pages/employer.js';
import Employers from './pages/employers.js';
import Events from './pages/events.js';
import Jobs from './pages/jobs.js';
import Login from './pages/login.js';
import Migrations from './pages/migrations.js';
import Misc from './pages/misc.js';
import Password from './pages/password.js';
import People from './pages/people.js';
import PeopleUpdate from './pages/peopleUpdate.js';
import PeopleVerify from './pages/peopleVerify.js';
import Print from './pages/print.js';
import Profile from './pages/profile.js';
import Reports from './pages/reports.js';
import Scos from './pages/scos.js';
import Tips from './pages/tips.js';
import Users from './pages/users.js';

if (!config.logged) {
	switch (config.url.data[0]) {
		default:
			Login.init();
			break;
	}
} else {
	if (window.forceUpdatePassword) {
		Password.init();
	} else {
		switch (config.url.data[0]) {
			case 'agencies':
				if (window.dataTables) {
					Agency.init();
				} else {
					Agencies.init();
				}
				break;

			case 'contact-support':
				ContactSupport.init();
				break;

			case 'data-entry':
				DataEntry.init();
				break;

			case 'data-import':
				DataImport.init();
				break;

			case 'data-inaccuracies':
				DataInaccuracies.init();
				break;

			case 'data-review':
				DataReview.init();
				break;

			case 'demographics':
				Demographics.init();
				break;

			case 'discussion':
				Discussion.init();
				break;

			case 'email-users':
				EmailUsers.init();
				break;

			case 'employer':
				Employer.init();
				break;

			case 'employers':
				Employers.init();
				break;

			case 'event-log':
			case 'events':
				Events.init();
				break;

			case 'jobs':
				Jobs.init();
				break;

			case 'manage-hints':
				Tips.init();
				break;

			case 'migrations':
				Migrations.init();
				break;

			case 'aes':
			case 'cities':
			case 'contact-types':
			case 'employer-types':
			case 'fiscal-years':
			case 'funding-sources':
			case 'funding-source-types':
			case 'job-codes':
			case 'job-types':
			case 'minimum-wages':
			case 'serv-types':
			case 'settings':
			case 'states':
			case 'people-sources':
			case 'races':
			case 'ethnicities':
				Misc.init();
				break;

			case 'password':
				Password.init();
				break;

			case 'people':
				People.init();
				break;

			case 'people-update':
				PeopleUpdate.init();
				break;

			case 'people-verify':
				PeopleVerify.init();
				break;

			case 'print':
				Print.init();
				break;

			case 'profile':
				Profile.init();
				break;

			case 'reports':
				Reports.init();
				break;

			case 'scos':
				Scos.init();
				break;

			case 'users':
				Users.init();
				break;

			case 'dashboard':
			case 'index':
			default:
				Dashboard.init();
				break;
		}
	}
}
