<template>
	<div class="data-entry">
		<div class="row">
			<div class="col col-sm-6">
				<a href="/data-entry" class="btn btn-primary btn-block-mobile">
					&lt; Choose another month
				</a>
			</div>
			<div class="col col-sm-4 pull-right">
				<div class="input-group">
					<div class="input-group-addon">
						<span class="glyphicon glyphicon-search"></span>
					</div>

					<input
						type="search"
						id="data-entry-filter"
						ref="filter"
						class="form-control"
						@change="filter($event.target.value)"
						@keyup="filter($event.target.value)"
						placeholder="Filter data entry list...">
				</div>
			</div>
		</div>

		<hr>

		<nav role="navigation" aria-label="Data entry navigation">
			<ul class="nav nav-tabs" id="data-entry-nav">
				<li v-for="tab in tabs" :class="tab.default ? 'active' : ''" :key="tab.key">
					<a href="#" data-toggle="tab" :data-target="`#data-entry-${tab.key}`">
						{{ tab.tabTitle }}
						<span class="badge">{{ sorted[ tab.key ].length }}</span>
					</a>
				</li>
			</ul>
		</nav>

		<div class="tab-content" id="data-entry-tabs">
			<entry-tab
				v-for="tab in tabs"
				:key="tab.key"
				:title="tab.title"
				:defaultTab="tab.default"
				:tabKey="tab.key"
				:people="sorted[ tab.key ]"
				:date="date"
				:agency="agency"
				:data_months="data_months"
				:imported="tab.imported"
				:filterText="filterText"></entry-tab>
		</div>
	</div>
</template>

<script>
	export default {
		props: [ 'people', 'entries', 'date', 'agency', 'data_months' ],

		data() {
			return {
				filterText: null,

				sorted: {},

				tabs: [
					{
						title: "Supported individuals who are employed to enter",
						tabTitle: "Employed",
						key: "employed",
						imported: false,
						default: true,
						filter: person => !this.importCheck(person) && !this.dataEntered(person) && this.hasActiveJobs(person),
					},

					{
						title: "Supported individuals who are unemployed",
						tabTitle: "Unemployed",
						key: "unemployed",
						imported: false,
						default: false,
						filter: person => !this.importCheck(person) && !this.dataEntered(person) && !this.hasActiveJobs(person),
					},

					{
						title: "Supported individuals already entered",
						tabTitle: "Already entered",
						key: "entered",
						imported: false,
						default: false,
						filter: person => !this.importCheck(person) && this.dataEntered(person),
					},

					{
						title: "Individuals imported from HCSIS",
						tabTitle: "HCSIS import",
						key: "imported",
						imported: true,
						default: false,
						filter: person => this.importCheck(person),
					},
				],
			}
		},

		methods: {
			dataEntered(person) {
				const activeJobs = person.jobs.filter(job => job.is_active_month);

				if (activeJobs.length === 0 &&
					person.entries.find(entry => +entry.job_id === 0)) {
					return true;
				}

				if (activeJobs.length > 0 &&
					person.entries.filter(entry => +entry.job_id > 0).length >= activeJobs.length) {
					return true;
				}

				return false;
			},

			importCheck(person) {
				if (!person._people_source) {
					return false;
				}

				if (+person._people_source.hcsis_import === 0) {
					return false;
				}

				if (!!person._people_agency.date_verify) {
					return false;
				}

				return true;
			},

			filter(value) {
				this.filterText = value;

				if (!value.length) {
					this.setPeople(this.people);
					return;
				}

				const pattern = new RegExp(value.replace(/[^a-z]/ig, ''), 'i');
				const filtered = this.people.filter(
					person => {
						const name = `${person.first_name}${person.last_name}${person.first_name}`.replace(/[^a-z]/ig, '')
						return pattern.test(name)
					});

				this.setPeople(filtered);
			},

			setPeople(people) {
				for (const tab of this.tabs) {
					this.sorted[ tab.key ] = people.filter(tab.filter);
				}
			},

			hasActiveJobs(person) {
				if (!person.jobs.length) {
					return false;
				}

				return !!person.jobs.find(job => job.is_active_month);
			},

			bindPageUnloadModal() {
				$(document).on('click', 'a:not([href="#"])', e => {
					const counts = {};

					for (const key of Object.keys(this.sorted)) {
						counts[ key ] = this.sorted[ key ].length;
					}

					if (counts.employed > 0 || counts.unemployed > 0) {
						const text = [];

						text.push(`There is still data to be submitted:\n`);

						if (counts.employed > 0) {
							text.push(`- ${counts.employed} employed individuals`);
						}

						if (counts.unemployed > 0) {
							text.push(`- ${counts.unemployed} unemployed individuals`);
						}

						text.push(`\nDo you wish to proceed?`);

						if (!confirm(text.join(`\n`))) {
							e.preventDefault()
						}
					}
				})
			},
		},

		created() {
			this.setPeople(this.people);
			this.bindPageUnloadModal();
		},
	}
</script>
