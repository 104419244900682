<template>
	<div :class="`list-group-item ${!isActiveMonth() ? 'disabled' : ''}`">
		<div class="row row-gutter-sm">
			<div class="col col-sm-10">
				<div class="row row-gutter-sm">
					<div class="col col-sm-5">
						<div class="form-control-static">
							<strong>{{ job.employer.name }}</strong><br>
							{{ job.employer_location.address_full }}
						</div>
					</div>

					<template v-if="!terminated">
						<div class="col col-sm-3">
							<div :class="fieldClass('job_type_id')">
								{{ job_type.code }} &ndash; {{ job_type.name }}
							</div>
						</div>

						<template v-if="legacy()">
							<div class="col col-sm-2">
								<div :class="fieldClass('hours')">
									{{ hoursValue() }}
								</div>
							</div>

							<div class="col col-sm-2">
								<div :class="fieldClass('earnings')">
									{{ earningsValue() }}<br>
									<small v-if="job.self_employed">
										(self-employed)
									</small>
								</div>
							</div>
						</template>

						<template v-else>
							<div class="col col-sm-2">
								<div :class="fieldClass('hours_week')">
									{{ hoursWeekValue() }}
								</div>
							</div>

							<div class="col col-sm-2">
								<div :class="fieldClass('hourly_rate')">
									{{ hourlyRateValue() }}<br>
									<small :class="fieldClass('flag_tipped', '')">
										({{ tippedValue() }})
									</small>
								</div>
							</div>
						</template>
					</template>

					<div class="col col-sm-7" v-else>
						<div class="form-control-static">
							<strong class="text-danger">Job ended on {{ terminated.date_end }} with code {{ termination_code.code }} – {{ termination_code.description }}</strong>
						</div>
					</div>
				</div>

				<div class="row row-gutter-sm" v-if="status !== 'submitted' && isActiveMonth()">
					<div class="col col-sm-5">
						<verify-job
							:person="person"
							:job="job"
							:date="date"
							@result="verifyJob"></verify-job>
					</div>

					<div class="col col-sm-7 text-center" v-if="!terminated">
						<button type="button" class="btn btn-default" @click="openModal()">
							Update job type, location, hours &amp; earnings
						</button>
					</div>
				</div>
			</div>

			<div class="col col-sm-2">
				<div class="form-control-static" v-if="status === 'submitted'">
					<em v-if="terminated" class="text-danger"><strong>Job terminated!</strong></em>
					<template v-else>
						<em><strong>Entry submitted!</strong></em>

						<button
							type="button"
							:class="`btn btn-primary btn-block btn-sm`"
							:disabled="!canSubmit()"
							@click="openModal()">
							Edit entry
						</button>
					</template>
				</div>

				<template v-else>
					<button
						type="button"
						:class="`btn btn-${terminated ? 'danger' : 'primary'} btn-lg`"
						:disabled="!canSubmit()"
						ref="button"
						@click="submit">
						{{ terminated ? 'Terminate Job' : 'Submit Entry' }}
					</button>

					<div class="form-control-static" v-if="!canSubmit() && isActiveMonth()">
						<em>Please update missing values.</em>
					</div>
				</template>
			</div>

			<div class="col col-sm-12" v-if="row && row.comments">
				<p><strong>Comments:</strong> {{ row.comments }}</p>
			</div>
		</div>

		<template v-if="requiresComment.required && status !== 'submitted'">
			<hr>
			<div class="row row-gutter-sm">
				<div class="col col-sm-6">
					<div class="alert alert-warning">
						{{ requiresComment.message }}
					</div>
				</div>
				<div class="col col-sm-6">
					<textarea class="form-control" name="comment" ref="comments" rows="4" placeholder="Comments..."></textarea>
				</div>
			</div>
		</template>

		<template v-if="!isActiveMonth()">
			<br>
			<div class="row row-gutter-sm">
				<div class="col col-sm-12">
					<div class="alert alert-info">
						<em>This is a seasonal job; this individual does not work during this month.</em>
					</div>
				</div>
			</div>
		</template>

		<form-modal ref="modal" @change="modalChange" @submit="update" v-bind="modal"></form-modal>
	</div>
</template>

<script>
	import moment from 'moment';
	import ajax from '../../../ajax.js';

	export default {
		props: [ 'person', 'job', 'date', 'agency', 'entry', 'data_months' ],

		data() {
			return {
				updates: {},

				hasUpdates: false,
				requiresComment: { required: false },

				modal: {
					title: null,
					inputs: [],
				},

				status: this.entry ? 'submitted' : 'unsubmitted',

				employer_location: this.job._employer_location,

				job_type: window.job_types[ this.obj().job_type_id ],

				job_update_codes: window.job_update_codes,

				termination_codes: window.termination_codes,
				termination_code: null,
				terminated: null,

				minimum_wages: window.minimum_wages,
				minimum_wage: null,

				earnings: null,
				hours: null,

				row: this.entry,
			}
		},

		methods: {
			getMinimumWage() {
				const tipped = this.job.flag_tipped;
				const date = moment(this.date);

				const wage = this.minimum_wages.find(wage => {
					if (tipped !== !!+wage.flag_tipped) {
						return false;
					}

					if (wage.date_start && date.isBefore(wage.date_start)) {
						return false;
					}

					if (wage.date_end && date.isAfter(wage.date_end)) {
						return false;
					}

					return true;
				});

				return wage;
			},

			async getRequiresComment() {
				const data = this.buildDataEntry();

				try {
					const res = await ajax({
						action: 'data_requires_comment',
						data,
					});

					this.requiresComment = res.requiresComment;

					if (res.requiresComment.required) {
						alert(res.requiresComment.message);
					}

					return res;
				} catch {
					return false;
				}
			},

			verifyJob(status, data) {
				if (status === 'verified') {
					this.status = status;

					this.$emit('result', status);
				} else if (status === 'terminated') {
					this.status = 'submitted';

					this.$emit('result', 'terminated');
					this.$emit('result', 'submitted');

					this.terminated = data;
					this.termination_code = this.termination_codes.find(
						({ id }) => +id === +data.termination_code_id);
				}
			},

			legacy() {
				return !!this.job.self_employed || !!this.job.flag_earnings;
			},

			canSubmit() {
				if (this.terminated) {
					return true;
				}

				if (!this.isActiveMonth()) {
					return false;
				}

				if (this.legacy()) {
					if (!this.job.hours) {
						return false;
					}

					if (!this.job.earnings) {
						return false;
					}
				} else {
					if (!this.job.hourly_rate) {
						return false;
					}

					if (!this.job.hours_week) {
						return false;
					}
				}

				return true;
			},

			terminate(data) {
				this.termination_code = this.termination_codes.find(
					({ id }) => +id === +data.termination_code_id);

				this.terminated = data;

				this.$refs.modal.hide();
			},

			async update(data) {
				if (data.hasOwnProperty('termination_code_id')) {
					this.terminate(data);
					return;
				}

				for (const property of Object.keys(data)) {
					const value = data[ property ];

					if (value.length === 0) {
						continue;
					}

					if (this.status === 'submitted') {
						this.row[ property ] = value;
					} else {
						this.updates[ property ] = value;
						this.job[ property ] = value;
					}

					if (property === 'job_type_id') {
						this.job_type = window.job_types[ value ];
					}

					if (property === 'hourly_rate') {
						this.minimum_wage = this.getMinimumWage();
					}

					this.hasUpdates = true;
				}

				this.$refs.modal.hide();

				if (this.status === 'submitted') {
					await this.submitUpdate();
				} else {
					await this.submit();
				}
			},

			async submit() {
				if (this.terminated) {
					await this.submitJob();

					this.status = 'submitted';
				} else {
					const data = this.buildDataEntry();

					if (!data.comments) {
						await this.getRequiresComment();

						if (this.requiresComment.required) {
							return;
						}
					}

					if (this.hasUpdates) {
						const jobHistory = await this.submitJobHistory();

						if (!jobHistory.success) {
							return;
						}
					}

					const submitted = await this.submitDataEntry();

					if (!submitted.success) {
						return;
					}

					this.status = 'submitted';
				}
			},

			async submitUpdate() {
				const data = this.buildDataEntry();

				if (!data.comments) {
					await this.getRequiresComment();

					if (this.requiresComment.required) {
						return;
					}
				}

				const submitted = await this.submitDataEntry();

				if (!submitted.success) {
					return;
				}

				this.status = 'submitted';
			},

			async submitJob() {
				const data = {
					model: 'Jobs',
					... this.job,
					... this.terminated,
				};

				return await ajax({
					action: 'manage',
					data,
					button: $(this.$refs.button),
				});
			},

			buildDataEntry() {
				return {
					model: 'EmploymentData',
					data_id: this.status === 'submitted' ? this.row.id : null,
					job_id: this.job.id,
					people_id: this.person.id,
					date: this.date,
					job_type_id: this.obj().job_type_id,
					flag_tipped: this.obj().flag_tipped,
					flag_earnings: this.obj().flag_earnings,
					agency_id: this.obj()?.agency_id || this.agency.id,
					type: this.obj()?.type || this.agency.reporting_frequency,
					people_ae_id: this.obj()?.people_ae_id || this.person._people_aes.id,
					comments: this.requiresComment.required ? this.$refs.comments.value : '',
					... (this.terminated || {}),
					... (this.legacy() ?
						{
							earnings: this.obj().earnings,
							hours: this.obj().hours,
						} :
						{
							hourly_rate: this.obj().hourly_rate,
							hours_week: this.obj().hours_week,
						})
				};
			},

			async submitDataEntry() {
				const data = this.buildDataEntry();

				try {
					const res = await ajax({
						action: 'manage',
						data,
						button: $(this.$refs.button),
					});

					this.row = data;
					this.row.id = res.PK;

					return res;
				} catch {
					return false;
				}
			},

			async submitJobHistory() {
				const data = {
					model: 'JobHistory',
					job_id: this.job.id,
					flag_earnings: this.job.flag_earnings,
					date_changed: this.date,
					... this.updates,
				};

				return await ajax({
					action: 'manage',
					data,
					button: $(this.$refs.button),
				});
			},

			modalChange(data) {
				if (data.job_update_code_id) {
					const code = this.job_update_codes
						.find(({ id }) => +id === +data.job_update_code_id);

					this.job.flag_earnings = !!+code.flag_earnings;

					this.setModalInputs(this.modal.type);
				}
			},

			async setModalInputs(property = null) {
				this.modal.inputs = [];
				this.modal.type = property;

				if (property === 'terminate') {
					this.modal.title = `Terminate ${this.person.last_name}, ${this.person.first_name}'s job at ${this.job.employer.name}`;

					this.modal.inputs.push({
						property: 'termination_code_id',
						label: 'Termination code',
						options: window.termination_codes
							.filter(code => !!code.code)
							.map(code => ({
								... code,
								name: `${code.code} – ${code.description}`,
							})),
						required: true,
					});

					this.modal.inputs.push({
						property: 'date_end',
						label: 'Termination date',
						required: true,
						placeholder: 'DD/MM/YYYY',
					});
				} else {
					if (this.status === 'submitted') {
						this.modal.title = `Update ${this.person.last_name}, ${this.person.first_name}'s entry at ${this.job.employer.name} on ${this.row.date}`;
					} else {
						this.modal.title = `Update ${this.person.last_name}, ${this.person.first_name}'s job at ${this.job.employer.name}`;
					}

					if (this.status !== 'submitted') {
						this.modal.inputs.push({
							property: 'job_type_id',
							label: 'New job type',
							current: `${this.job_type.code} – ${this.job_type.name}`,
							options: Object.values(window.job_types)
								.filter(({ flag_legacy }) => +flag_legacy === 0)
								.map(job_type => ({
									... job_type,
									name: `${job_type.code} – ${job_type.name}`,
								})),
						});

						if (!this.employer_locations) {
							this.employer_locations = (await ajax({
								action: 'employer_locations_list',
								data: { employer_id: this.employer_location.employer_id },
							}))
							.locations
							.map(item => ({
								... item,
								name: item.address_full,
							}));
						}

						this.modal.inputs.push({
							property: 'employer_location_id',
							label: 'New location',
							current: this.employer_location.address_full,
							// noDefault: true,
							options: this.employer_locations,
						});

						this.modal.inputs.push({
							property: 'job_update_code_id',
							label: 'Job update code',
							options: this.job_update_codes
								.filter(code => !!code.code)
								.map(code => ({
									... code,
									name: `${code.code} – ${code.description}`,
								})),
							required: true,
						});
					}

					this.modal.inputs.push({
						property: 'hours',
						label: 'Total hours',
						current: this.hoursValue(),
						hidden: !this.legacy(),
					});

					this.modal.inputs.push({
						property: 'earnings',
						label: 'Total earnings',
						current: this.earningsValue(),
						hidden: !this.legacy(),
					});

					this.modal.inputs.push({
						property: 'hours_week',
						label: 'Average hours/week',
						current: this.hoursWeekValue(),
						hidden: this.legacy(),
					});

					this.modal.inputs.push({
						property: 'hourly_rate',
						label: 'Hourly rate',
						current: this.hourlyRateValue(),
						hidden: this.legacy(),
					});

					if (this.status !== 'submitted') {
						this.modal.inputs.push({
							property: 'flag_tipped',
							label: 'Do they receive tips?',
							current: this.tippedValue(),
							options: [
								{ id: 0, name: "does not receive tips" },
								{ id: 1, name: "receives tips" },
							],
						});
					}
				}
			},

			async openModal(property = null) {
				await this.setModalInputs();
				this.$refs.modal.show();
			},

			fieldClass(field, append = 'form-control-static') {
				const updates = this.updates.hasOwnProperty(field);

				return `${append} ${updates ? 'text-info' : ''}`;
			},

			obj() {
				return this.row || this.job;
			},

			tippedValue() {
				return this.job.flag_tipped ?
					'receives tips' :
					'does not receive tips'
			},

			hoursWeekValue() {
				if (!this.obj().hours_week) {
					return 'Please update';
				}

				return `${this.obj().hours_week} hours/week`;
			},

			hourlyRateValue() {
				if (!this.obj().hourly_rate) {
					return 'Please update';
				}

				return `${this.currency(this.obj().hourly_rate)}/hour`;
			},

			hoursValue() {
				if (!this.obj().hours) {
					return 'Please update';
				}

				return `${this.obj().hours} hours`;
			},

			earningsValue() {
				if (!this.obj().earnings) {
					return 'Please update';
				}

				return `${this.currency(this.obj().earnings)}`;
			},

			currency(value) {
				if (value === null) {
					return '';
				}

				value = value.toString();

				return new Intl.NumberFormat('en-US', {
					style: 'currency',
					currency: 'USD',
				})
				.format(value.replace(/[^0-9\.]/g, ''));
			},

			isActiveMonth() {
				if (!this.job.flag_seasonal) {
					return true;
				}

				for (const month of this.data_months) {
					if (this.job[`flag_seasonal_${month}`]) {
						return true;
					}
				}

				return false;
			},
		},

		created() {
			this.minimum_wage = this.getMinimumWage();
		},
	}
</script>
