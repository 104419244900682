import $ from '../jquery.js';
import _ from 'lodash';
import moment from 'moment';
import { saveSvgAsPng } from 'save-svg-as-png';
import download from '../includes/download.js';

const data = window.data;

const Reports = {};

Reports.data = window.reportsData;

Reports.selectors = function() {
	this.form = $('#report-form');
	this.ae_id = this.form.find('[name="ae_id"]');
	this.agency_id = this.form.find('[name="agency_id"]');
	this.sco_id = this.form.find('[name="sco_id"]');
};

Reports.bindings = function() {
	var that = this;

	_.each([
		{
			input: 'ae_id',
			content: 'scos',
			values: 'sco_aes',
			target: 'sco_id',
			name: 'SCO',
			hasAll: data.sco_all,
		},
		{
			input: 'agency_id',
			content: 'aes',
			values: 'agency_aes',
			target: 'ae_id',
			name: 'AE',
			hasAll: data.ae_all,
		},
	], function(vars) {
		that[vars.input].on('change', function() {
			var val = $(this).val(), tmp = [], entities = [], prev;

			if(val > 0) {
				tmp = data[vars.values][val];
			} else {
				tmp = data[vars.content];
			}

			_.each(tmp, function(value, key) {
				entities.push({value: value, key: key});
			});

			entities.sort(function(a, b) {
				if(a.value > b.value) {
					return 1;
				} else if(a.value < b.value) {
					return -1;
				} else {
					return 0;
				}
			});

			prev = that[vars.target].val();

			that[vars.target]
				.html(that.templates.list({type: vars.name, entities: entities, hasAll: vars.hasAll}))
				.val(prev)
				.trigger('change');
		}).trigger('change');
	});

	$('#report-print-btn').on('click', function(e) {
		e.preventDefault();

		if ($('#reports-print-modal').length) {
			$('#reports-print-modal').modal('show');
		} else {
			Reports.print();
		}
	});

	// FIXME: extra page break before agency profiles when enabled

	$('#reports-print-modal-btn').on('click', function(e) {
		e.preventDefault();

		const sections = $('#reports-print-modal input[name="print_sections[]"]').map(function () { return $(this).val(); }).toArray();
		const visible = $('#reports-print-modal input[name="print_sections[]"]:checked').map(function () { return $(this).val(); }).toArray();

		for (const section of sections) {
			if (!visible.includes(section)) {
				$(`#${section}`).addClass('hidden-print');
			}
		}

		window.print();

		for (const section of sections) {
			if (!visible.includes(section)) {
				$(`#${section}`).removeClass('hidden-print');
			}
		}

		$('#reports-print-modal').modal('hide');
	});
};

Reports.init = function() {
	this.selectors();
	this.bindings();

	const callback = this.getCallback(window.config.url.data[1]);

	if(callback && window.reportsData) {
		if ([ 'infographic' ].includes(callback)) {
			this[callback]();
		} else {
			window.google.load('visualization', '1.0', {'packages':['corechart']});
			window.google.setOnLoadCallback(this[callback]);
		}
	}
};

Reports.templates = {
	list: _.template($('#report-list-template').html()),
};

Reports.options = {
	legend: {
		position: 'none',
	},
	hAxis: {
		maxTextLines: 3,
	},
	vAxis: {
		gridlines: {
			count: 10,
		},
	},
	pointSize: 5,
	fontName: 'Helvetica Neue, Helvetica, Arial, sans-serif',
	height: 400,
	chartArea: {
		height: 250,
		top: 16,
	},
	titlePosition: 'none',
};

Reports.jobTypes = function() {
	Reports.drawChart({
		type: 'LineChart',
		options: $.extend({}, Reports.options, {vAxis: {format: '#.#%', gridlines: {count: 10}}}),
		callback: function(chart,data) {
			var formatter = new window.google.visualization.NumberFormat({pattern: '#.#%'});
			formatter.format(data, 1);
		},
	});
};

Reports.yearsOnJob = function() {
	Reports.drawChart({type: 'LineChart', options: $.extend({}, Reports.options, {
		height: 550,
		chartArea: {
			height: 450,
			top: 16,
		},
	})});
};

Reports.ageRange = function() {
	Reports.drawChart({type: 'LineChart', options: $.extend({}, Reports.options, {height: 300})});
};

Reports.newJobs = function() {
	Reports.drawChart({type: 'LineChart', options: $.extend({}, Reports.options, {height: 300})});
};

Reports.notReceivingServices = function() {
	Reports.drawChart({type: 'LineChart', options: $.extend({}, Reports.options, {
		height: 540,
		chartArea: {
			height: 450,
			top: 16,
		},
	})});
};

Reports.percentEmployed = function() {
	Reports.drawChart({
		type: 'ComboChart',
		callback: function(chart,data) {
			var formatter = new window.google.visualization.NumberFormat({pattern: '#.#%'});
			formatter.format(data, 1);
		},
		options: $.extend({}, Reports.options, {
			height: 540,
			/*vAxis: {
				format: '#.#%',
				viewWindow: {
					max: 1
				}
			},*/
			vAxes: [
				{
					format: '#.#%',
					gridlines: {
						count: 11,
					},
					viewWindow: {
						min: 0,
						max: 1,
					},
				},
				{
					gridlines: {
						count: 11,
					},
				},
			],
			chartArea: {
				height: 450,
				top: 16,
			},
			seriesType: "bars",
			series: {
				0: {
					type: "line",
					targetAxisIndex: 0,
				},
				1: {
					type: "bars",
					targetAxisIndex: 1,
				},
				2: {
					type: "bars",
					targetAxisIndex: 1,
				},
			},
		}),
	});
};

Reports.stakeholder = function() {
	Reports.drawChart({
		type: 'ColumnChart',
		id: 'report-chart-breakdown_job_hours_chart',
		options: $.extend({}, Reports.options, {
			vAxis: {
				gridlines: {
					count: 11,
				},
			},
			chartArea: {
				height: 350,
			},
			height: 400,
			isStacked: false,
		}),
	}, Reports.data.breakdown_job_hours_chart);

	Reports.drawChart({
		type: 'ColumnChart',
		id: 'report-chart-job_types_chart',
		options: $.extend({}, Reports.options, {
			vAxis: {
				gridlines: {
					count: 11,
				},
			},
			chartArea: {
				height: 300,
			},
			bar: {
				groupWidth: '90%',
			},
			hAxis: {
				slantedText: true,
				slantedTextAngle: 90,
				textStyle: {
					// fontSize: 12,
				},
			},
			height: 400,
			isStacked: false,
		}),
	}, Reports.data.job_types_chart);
};

Reports.infographic = function() {
	const downloadPng = $('#infographic-download-png');

	downloadPng.on('click', function(e) {
		e.preventDefault();

		saveSvgAsPng(
			$('#infographic')[0],
			`EmployStat Infographic on ${moment().format('YYYY-MM-DD')}.png`,
			{
				scale: 2,
			});
	});

	const downloadText = $('#infographic-download-text');

	downloadText.on('click', function(e) {
		e.preventDefault();

		const text = $('#infographic-text')
			.text()
			.replace(/\n\n+/g, "\n")
			.replace(/[ \t]+/g, " ")
			.replace(/\n /g, "\n")
			.trim();

		download(text, `EmployStat Infographic on ${moment().format('YYYY-MM-DD')}.txt`, 'text/plain');
	});
};

Reports.jobHiresTerminations = function() {
	Reports.drawChart({
		type: 'LineChart',
		options: $.extend({}, Reports.options, {
			height: 550,
			legend: {
				position: 'bottom',
			},
			chartArea: {
				height: 475,
				top: 16,
			},
			vAxis: {
				gridlines: {
					count: 8,
				},
			},
			vAxes: {
				0: {
					gridlines: {
						count: 8,
					},
				},
				1: {
					gridlines: {
						count: 8,
					},
				},
			},
			series: {
				0: {
					color: 'green',
					targetAxisIndex: 0,
				},
				1: {
					color: 'orange',
					targetAxisIndex: 0,
				},
				2: {
					color: 'blue',
					targetAxisIndex: 0,
				},
				3: {
					color: 'purple',
					targetAxisIndex: 1,
				},
				4: {
					color: 'purple',
					targetAxisIndex: 1,
					lineDashStyle: [ 3, 3 ],
				},
			},
		}),
	}, undefined, 150);
};

Reports.drawChart = function(opts, data, widthOffset = 100) {
	if (typeof opts.id === 'undefined' || opts.id.length === 0) {
		opts.id = 'report-chart';
	}

	if (!document.getElementById(opts.id)) {
		return;
	}

	const chart = new window.google.visualization[opts.type](
		document.getElementById(opts.id));

	if (typeof data === 'undefined') {
		data = Reports.data;
	}
	//opts.options.width = $('#' + opts.id).width() * 0.95;
	opts.options.chartArea.width = $('#' + opts.id).width() - widthOffset;

	const table = window.google.visualization.arrayToDataTable(data);

	if (typeof opts.callback === 'function') {
		opts.callback(chart, table);
	}

	chart.draw(table, opts.options);

	if (!opts.noBind) {
		$(window).on('resize.' + opts.id, function() {
			Reports.drawChart($.extend({}, opts, {noBind: true}), data, widthOffset);
		});
	}

	return { chart, data };
};

Reports.getCallback = function(report) {
	var func = _.camelCase(report);

	if(typeof Reports[func] === 'function') {
		return func;
	}

	return null;
};

Reports.print = function() {
	alert('Note that most reports are best printed in landscape orientation as opposed to portrait.');
	window.print();
}

export default Reports;