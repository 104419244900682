<template>
	<file-input
		:drafts="drafts"
		:settings="settings"
		:types="types"
		v-if="step === 'file'"
		@complete="loadFile"></file-input>

	<choose-headers
		:headers="headers"
		:config="config"
		:settings="settings"
		v-else-if="step === 'headers'"
		@back="setStepBack('file')"
		@saveDraft="saveDraft"
		@complete="setHeaders"></choose-headers>

	<match
		:matches="matches"
		:config="config"
		:settings="settings"
		v-else-if="step === 'match'"
		@back="setStepBack('headers')"
		@saveDraft="saveDraft"
		@complete="setMatch"></match>

	<review-data
		:data="finalData"
		:config="config"
		:settings="settings"
		v-else-if="step === 'rows'"
		@back="setStepBack('match')"
		@saveDraft="saveDraft"
		@complete="submitData"></review-data>

	<results
		:results="results"
		v-else-if="step === 'results'"></results>
</template>

<script>
	import ajax from '../../../ajax.js';
	import lodash from 'lodash';
	
	export default {
		props: [ 'csvData', 'combinedData', 'finalData', 'config', 'settings', 'drafts', 'types' ],

		data() {
			return {
				step: "file",
				results: {},
			};
		},

		computed: {
			headers() {
				return this.csvData.length ? Object.keys(this.csvData[0]) : [];
			},

			matches() {
				if (!this.combinedData.length || !this.settings.import_type) {
					return [];
				}

				const matches = lodash.chain(this.combinedData)
					.map(this.config.match_column)
					.uniq()
					.filter('length')
					.value()

				return matches;
			},
		},

		methods: {
			async saveDraft(input) {
				const data = {
					model: 'DataImportDrafts',
					... this.settings,
				};

				switch (this.step) {
					case 'headers':
						data.headers = input;
						break;

					case 'match':
						data.match = input;
						break;

					case 'rows':
						data.excluded = input;
						break;
				}

				try {
					await ajax({
						action: 'manage',
						data,
					});
					window.location.reload();
				} catch {
					alert("The draft could not be saved.");
				}
			},

			setStep(step) {
				this.step = step;
				window.scrollTo(0, 0);
			},

			setStepBack(step) {
				this.$emit('back', step);
				this.setStep(step);
			},

			loadFile(result) {
				this.$emit('load-file', result);
				this.setStep('headers');
			},

			setHeaders(headers) {
				this.$emit('set-headers', headers);
				this.setStep('match');
			},

			async setMatch(matches) {
				this.$emit('set-match', matches);

				const rows = this.prepareData(
					row => !row.invalid,
					this.combinedData);

				const data = {
					rows,
					type: this.settings.import_type,
				};

				const res = await ajax({
					action: 'dataImportDryRun',
					data,
				});

				this.$emit('add-notes', res.results);
				this.setStep('rows');

				return res;
			},

			async submitData(excluded) {
				this.$emit('choose-rows', excluded);

				const rows = this.prepareData(
					row => (row.included &&
						[ 'new', 'updated' ].includes(row.status)),
					this.finalData);

				const data = {
					rows,
					data_import_draft_id: this.settings.data_import_draft_id,
					type: this.settings.import_type,
				};

				const res = await ajax({
					action: 'dataImportSubmit',
					data,
				});

				this.results = res.results;

				this.results.invalid = this.finalData.filter(({ invalid }) => invalid).length;
				this.results.unchanged = this.finalData.filter(({ status }) => status === "unchanged").length;

				this.results.skipped = this.finalData.length - _.sum(Object.values(this.results));

				this.setStep('results');

				return res;
			},

			prepareData(check, data) {
				const rows = data.filter(check);
				const picked = rows.map(row => _.pick(row, [
					'first_name', 'last_name', 'mci', 'index',
					... this.config.update_columns,
					... this.config.match_id,
				]));

				return JSON.stringify(picked);
			},
		}
	}
</script>
