import $ from '../jquery.js';
import config from '../config.js';
import ajax from '../ajax.js';

const EmailUsers = {};

EmailUsers.init = function() {
	const form = $("#email-users-form");

	$("#email-users-ae select").change(function() {
		if(this.value === '0') {
			return false;
		}

		document.location = config.url.base + config.route + '/' + this.value;
	});

	form.on('click', '.list-group a.list-group-item', function(e){
		if($(e.target).is(':checkbox')) {
			return;
		} else {
			e.preventDefault();
		}

		const checkbox = $(this).find(':checkbox');
		checkbox[0].checked = !checkbox[0].checked;
		checkbox.change();
	});

	form.on('change', '.list-group a.list-group-item :checkbox', function(){
		const container = $(this).closest('a.list-group-item');
		container.toggleClass('active').attr('aria-checked', container.hasClass('active'));
		$(container).closest('.panel').find('.panel-footer span').text(container.parent().find('> a.active').length);
	});

	form.on('click', '.panel-footer .btn-toggle', function(e) {
		e.preventDefault();
		const type = $(this).data('type');
		let items = $(this).closest('.panel').find('.list-group a.list-group-item');

		if(type !== 'all') {
			items = items.filter('.toggle-' + type);
		}

		if(items.length > items.filter('.active').length) {
			items.not('.active').trigger('click');
		} else {
			items.trigger('click');
		}
	});

	form.on('submit', async function(e) {
		e.preventDefault();
		const data = $(this).serializeJSON();
		const button = $(this).find('> button[type="submit"]');

		if(button.is(':disabled')) {
			return;
		}

		button.button('loading');

		try {
			await ajax({
				action: 'email_users',
				data,
			})
			alert("The email was sent successfully.");
		} catch { false; }

		button.button('reset');
	});
};

export default EmailUsers;
