<template>
	<p v-if="date">
		<small>Last verified {{ formatDate(date) }}</small>
	</p>
	<p v-else>
		<small><em>Has never been verified.</em></small>
	</p>
</template>

<script>
	import moment from 'moment';

	export default {
		props: [ 'date' ],

		methods: {
			formatDate(date) {
				return moment(date).format('MMMM Do, YYYY');
			},
		},
	};
</script>
