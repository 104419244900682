import $ from '../jquery.js';
import ajax from '../ajax.js';
import config from '../config.js';
import initDataTables from '../elements/dataTables.js';
import agencyView from '../elements/agencyView.js';

var DataInaccuracies = {};

DataInaccuracies.init = function() {
	this.selectors();
	this.bindings();
	this.panelInit();

	agencyView.init();
};

DataInaccuracies.selectors = function() {
	this.form = $('#data-inaccuracies');
	this.total = $('#data-inaccuracies-total');
};

DataInaccuracies.bindings = function() {
	var that = this;

	//Submit stuff
	that.form.on('submit', async function(e) {
		e.preventDefault();
		try {
			const res = await ajax({
				action: 'dataInaccuracies',
				data: $(this).serializeArray(),
			});
			that.submitComplete(res);
		} catch { false; }
	});

	//What if they aren't employed/supported?
	/*that.form.on('click', '.btn-danger', function(e) {
		e.preventDefault();
		var group = $(this).closest('.btn-group'),
			input = group.next();
		group.addClass('hide');
		input.find('input, select').prop('required', true);
		input.removeClass('hide').find('input').focus();
	});*/

	//Let's add some skipping
	that.form.on('click', '.data-inaccuracies-skip', function() {
		that.form[0].reset();
		that.form.find('input[required]').prop('required', false);
		that.form.find('[name="verify_skip"]').val($(this).data('skip') + 1);
	});

	//Let's add some deleting
	that.form.on('click', '.data-inaccuracies-delete', async function() {
		if(!confirm("Are you sure you wish to delete this entry?")) {
			return;
		}

		var data = {};
		data.model = 'EmploymentData';
		data.PK = that.form.find('[name="data_id"]').val();
		data.isInaccuraciesPage = true;
		data['delete'] = true;

		try {
			const res = await ajax({
				action: 'dataInaccuracies',
				data,
			});
			that.submitComplete(res);
		} catch { false; }
	});
};

DataInaccuracies.submitComplete = function(res) {
	var that = this;
	that.form.empty();
	if(res.complete) {
		alert("Thanks for correcting all of this issues!");
		window.location = config.url.base;
	} else {
		that.form.append($.parseHTML(res.html, document, true));
		that.form[0].reset();
		//that.form.find('select').val(0);
		that.total.text(res.total);
		that.panelInit();
	}
};

DataInaccuracies.panelInit = function() {
	if($('#peopleagencies-table').length) {
		initDataTables(window.dataTable);
	}
};

export default DataInaccuracies;