import $ from '../jquery.js';
import DataTablesBase from '../datatables.js';
import config from '../config.js';

var DataReview = new DataTablesBase();

DataReview.bindings = function() {
	var that = this;

	that.table.on('click', '.btn-edit.disabled', function(e) {
		e.preventDefault();
		alert("This entry is too far in the past to be edited.  Please contact an administrator if you'd like to do so.");
	});

	if(that.toggle) {
		that.toggle.click(function(e) {
			e.preventDefault();
			if($(this).hasClass('disabled')) {
				return;
			}
			var PK = that.table.find('tr.success').pluck('id');
			window.open(config.url.base + 'print?type=' + that.model + '&id=' + PK.join(','));
		});
	}

	$('.dataTables_filters input[name="data_status"]').on('click', function() {
		var parent = $(this).closest('.input-row');
		if(this.checked) {
			parent.addClass('alert-warning');
		} else {
			parent.removeClass('alert-warning');
		}
	});
};

export default DataReview;