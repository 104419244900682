import $ from '../jquery.js';
import ajax from '../ajax.js';

const EmployerLookup = {
	timeout: null,
	type: 'employer',

	results: {
		element: $('#employers-lookup-result'),
		body: $('#employers-lookup-result .panel-body'),
		addNewBtn: $('#employers-lookup-new'),
	},

	content: null,
	dialog: null,
	employer: null,

	reset() {
		this.results.body.empty();
		this.results.element.addClass('hidden');

		this.content.addClass('hidden');
		this.setType('employer');
		this.employer = null;
	},

	show() {
		this.results.element.removeClass('hidden');
		this.content.addClass('hidden');
	},

	hide() {
		this.results.body.empty();
		this.results.element.addClass('hidden');

		this.content.removeClass('hidden');
	},

	setType(type) {
		this.type = type;
		this.results.addNewBtn.find('span').text(type);
	},

	async lookup(name) {
		const { employers, short } = await ajax({
			action: 'employer_check',
			data: { name },
		});

		this.setType('employer');
		this.employer = null;

		if (!employers) {
			this.results.addNewBtn.prop('disabled', short);

			this.results.body.html(short ?
				`<p><em>Continue typing to reveal existing employers.</em></p>` :
				`<p><em>No results.</em></p>`);

			this.show();

			return;
		}

		this.results.addNewBtn.prop('disabled', false);

		const content = [];

		content.push('<p><strong>The name entered matches an existing employer &ndash; rather than creating a new employer, please add a new location to an existing one:</strong></p>');

		content.push('<ul>');

		for (const employer of employers) {
			content.push(`<li>
				<a href="#" data-employer-id="${employer.id}">
					${employer.name}
				</a>
			</li>`);
		}

		content.push('</ul>');

		this.results.body.html(content.join(''));

		this.results.body.find('[data-employer-id]').on('click', e => {
			e.preventDefault();

			const id = +$(e.target).data('employerId');
			const employer = employers.find(employer => +employer.id === id);

			this.chooseEmployer(employer);
		})

		this.show();
	},

	showLocations(employer) {
		const locations = employer._employer_locations;
		const content = [];

		this.setType('location');

		content.push('<p><strong>Choose a location, or create a new one:</strong></p>');

		content.push('<ul>');

		for (const location of locations) {
			if (!location.address_full) {
				continue;
			}

			content.push(`<li>
				<a href="#" data-location-id="${location.id}">
					${location.address_full}
				</a>
			</li>`);
		}

		content.push('</ul>');

		this.results.body.html(content.join(''));

		this.results.body.find('[data-location-id]').on('click', e => {
			e.preventDefault();

			const id = +$(e.target).data('locationId');
			const location = locations.find(location => +location.id === id);

			this.chooseLocation(employer, location);
		})

		this.show();
	},

	chooseEmployer(employer) {
		this.employer = employer;

		this.callbacks.chooseEmployer.call(this, employer);
	},

	chooseLocation(employer, location) {
		this.callbacks.chooseLocation.call(this, employer, location);
	},

	defaultCallbacks: {
		chooseEmployer(employer) {
			window.location = `${window.config.url.base}employer/${employer.id}`;
		},
		chooseLocation(employer, location) {
			console.log(employer, location);
		},
		newEmployer() {
			this.hide();
		},
		newLocation(employer) {
			console.log(employer);
		},
	},

	bind({
		dialog = $('#employers-dialog'),
		content = $('#employers-dialog-fields, #employers-dialog .modal-footer'),
		input = $('#employers-dialog [name="name"]'),
		callbacks = {},
	}) {
		this.dialog = dialog;
		this.content = content;
		this.input = input;
		this.callbacks = Object.assign({}, this.defaultCallbacks, callbacks);

		this.dialog.on('show.bs.modal', () => this.reset());

		this.input.on('keyup', (e) => {
			this.clearTimeout();
			this.setTimeout(e.target.value);
		});

		this.results.addNewBtn.on('click', e => {
			e.preventDefault();

			if (this.type === 'location') {
				this.callbacks.newLocation.call(this, this.employer);
			} else {
				this.callbacks.newEmployer.call(this);
			}
		})
	},

	clearTimeout() {
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
	},

	setTimeout(name) {
		this.timeout = setTimeout(() => {
			this.lookup(name);
		}, 750);
	},
};

export default EmployerLookup;
