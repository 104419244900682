var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.person
    ? _c("div", { staticClass: "alert alert-success" }, [
        _c("p", [_vm._v("You have no people left to verify currently.")])
      ])
    : _c("verify-entry", {
        key: _vm.person.id,
        attrs: {
          person: _vm.person,
          date: _vm.date,
          agency: _vm.agency,
          peopleRemaining: _vm.remaining
        },
        on: { complete: _vm.next }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }