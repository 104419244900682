import $ from '../jquery.js';
import ajax from '../ajax.js';
import employerLookup from './employerLookup.js';

const jobsDialog = {};

jobsDialog.employer_id = null;
jobsDialog.employer_location_id = null;
jobsDialog.self_employed = false;

jobsDialog.init = function() {
	this.selectors();
	this.bindings();
	this.modals();

	this.footer.addClass('hide');

	const that = this;

	employerLookup.bind({
		dialog: this.modal,
		content: $('#employers-dialog-fields, #employers-dialog .modal-footer'),
		input: this.modal.find('[name="employer_name"]'),
		callbacks: {
			chooseEmployer(employer) {
				this.showLocations(employer);
			},
			chooseLocation(employer, location) {
				this.reset();

				that.setEmployer(employer);
				that.setLocation(location);
				that.employerSelected();
			},
			newEmployer() {
				that.showEmployerModal();
				this.hide();
			},
			newLocation(employer) {
				that.showLocationModal(employer);
			},
		},
	});
};

jobsDialog.employerSelected = function() {
	this.choose.addClass('hide');
	this.footer.removeClass('hide');
	this.info.removeClass('hide');
	this.chosen.removeClass('hide');
}

jobsDialog.modals = function() {
	const that = this;

	this.employerModal
		.on('submit', 'form', async function(e) {
			e.preventDefault();

			const form = $(this);
			const modal = $(this).closest('.modal');
			const data = form.serializeJSON();

			data['return'] = 'data';

			try {
				const res = await ajax({
					action: 'manage',
					data,
				});

				modal.forceHide();

				that.setEmployer(res.data);
				that.setLocation(Object.values(res.data._employer_locations)[0]);

				if (that.self_employed) {
					that.form.find('[name="job_type_id"]').val(
						window.config.settings.job_types.self_employed);

					that.form.find('[name="self_employed"]').prop('checked', true);
				}

				that.employerSelected();
			} catch { false; }
		})
		.on('hidden.bs.modal', function() {
			that.modal.modal('show');
		});

	this.locationModal
		.on('submit', 'form', async function(e) {
			e.preventDefault();

			const form = $(this);
			const modal = $(this).closest('.modal');
			const data = form.serializeArray();

			data.map(row => {
				if (row.name === 'return') {
					row.value = 'data';
				}

				return row;
			});

			try {
				const res = await ajax({
					action: 'manage',
					data,
				});

				modal.forceHide();

				that.setEmployer(res.data._employer);
				that.setLocation(res.data);

				that.employerSelected();
			} catch { false; }
		})
		.on('hidden.bs.modal', function() {
			that.modal.modal('show');
		});
};

jobsDialog.showEmployerModal = function() {
	this.modal.forceHide();
	this.employerModal.modal('show');
}

jobsDialog.showLocationModal = function(employer = null) {
	this.modal.forceHide();
	this.locationModal.find('[name="employer_id"]').val(employer?.id || this.employer_id);
	this.locationModal.modal('show');
}

jobsDialog.bindings = function() {
	const that = this;

	//Prevent tabbing out in some areas
	that.choose.preventTabOut();

	that.modal.on('hidden.bs.modal.reset', function() {
		that.reset();
	});

	//Go back to the beginning.
	$('.jobs-dialog-choose').click(function(e) {
		e.preventDefault();
		that.reset();
	});

	that.modal.find('[name="flag_seasonal"]').on('change.seasonal', function() {
		that.seasonal.toggleClass('hidden', !$(this).prop('checked'))
	})
};

jobsDialog.setEmployer = function(employer) {
	this.employer_id = employer.id;

	$('#jobs-dialog-chosen-employer').text(employer.name);
}

jobsDialog.setLocation = function(location) {
	this.employer_location_id = location.id;
	this.chosen.find('input').val(location.id);

	$('#jobs-dialog-chosen-employer-location').text(location.address_full);
}

jobsDialog.reset = function() {
	this.chosen.add(this.info).addClass('hide')
	this.modal.find('input, select').not(':checkbox, :radio, [type="hidden"]').val('');
	this.choose.removeClass('hide').focusFirst();
	this.employer_id = null;
	this.employer_location_id = null;
	this.footer.addClass('hide');
	this.seasonal.addClass('hide');
};

jobsDialog.selectors = function() {
	this.modal = $('#jobs-dialog');
	this.employerModal = $('#employers-dialog');
	this.locationModal = $('#employerlocations-dialog');
	this.choose = $('#jobs-dialog-choose');
	this.chosen = $('#jobs-dialog-chosen');
	this.info = $('#jobs-dialog-info');
	this.form = this.modal.find('form');
	this.footer = this.modal.find('.modal-footer');
	this.seasonal = this.modal.find('.jobs_seasonal_months');
};

export default jobsDialog;