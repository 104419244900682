import $ from '../jquery.js';
import ajax from '../ajax.js';

const Profile = {};

Profile.init = function() {
	$('#update-profile-form').submit(async function(e) {
		e.preventDefault();
		try {
			await ajax({
				action: 'updateProfile',
				data: $(this).serializeJSON(),
			});

			alert("Your profile information was updated successfully");
			$("input:password").val('');
		} catch { false; }
	});
};

export default Profile;