import $ from '../jquery.js';
import ajax from '../ajax.js';

const ContactSupport = {};

ContactSupport.init = function() {
	ContactSupport.preventSubmit();

	$("#contact").submit(async function(e) {
		e.preventDefault();

		if (ContactSupport.verified === false) {
			alert('Please complete the reCAPTCHA challenge.');
			return;
		}

		try {
			await ajax({
				action: 'contact',
				data: $(this).serializeJSON(),
			});

			$("#contact").hide();
			$("#contact-sent").removeClass('hide');
		} catch {
			ContactSupport.preventSubmit();
			window.grecaptcha.reset();
		}
	});
};

ContactSupport.allowSubmit = function() {
	ContactSupport.verified = true;
}

ContactSupport.preventSubmit = function() {
	ContactSupport.verified = false;
}

window.reCaptchaAllow = ContactSupport.allowSubmit;
window.reCaptchaPrevent = ContactSupport.preventSubmit;

export default ContactSupport;
