var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "verify-job" },
    [
      _vm.status === "verified" || _vm.date <= _vm.job.date_verify
        ? _c("div", { staticClass: "form-control-static" }, [
            _c("strong", [
              _c("em", [
                _vm._v(
                  "Employment status verified on " +
                    _vm._s(_vm.dateVerified()) +
                    "."
                )
              ])
            ])
          ])
        : _vm.status === "terminated"
        ? _c("div", { staticClass: "form-control-static" }, [_vm._m(0)])
        : _c("div", { staticClass: "btn-group" }, [
            _c(
              "button",
              {
                ref: "button",
                staticClass: "btn btn-success",
                attrs: { type: "button" },
                on: { click: _vm.verifyJob }
              },
              [_vm._v("\n\t\t\tStill employed\n\t\t")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-danger",
                attrs: { type: "button" },
                on: { click: _vm.showModal }
              },
              [_vm._v("\n\t\t\tNo longer employed\n\t\t")]
            )
          ]),
      _vm._v(" "),
      _c(
        "form-modal",
        _vm._b(
          { ref: "modal", on: { submit: _vm.terminateJob } },
          "form-modal",
          _vm.modal,
          false
        )
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("strong", [_c("em", [_vm._v("Job terminated")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }