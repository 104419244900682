var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "panel-footer clearfix" }, [
    !_vm.firstStep
      ? _c(
          "button",
          {
            staticClass: "btn btn-default",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.$emit("back")
              }
            }
          },
          [_vm._v("\n\t\tBack\n\t")]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "button",
      { staticClass: "btn btn-primary pull-right", attrs: { type: "submit" } },
      [_vm._v("\n\t\tContinue\n\t")]
    ),
    _vm._v(" "),
    !_vm.firstStep
      ? _c(
          "button",
          {
            staticClass: "btn btn-default pull-right",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.$emit("saveDraft")
              }
            }
          },
          [_vm._v("\n\t\tSave Draft\n\t")]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }