import $ from '../jquery.js';
import DataTablesBase from '../datatables.js';
import ajax from '../ajax.js';
import employerLookup from '../elements/employerLookup.js';

const Employers = new DataTablesBase();

Employers.bindings = function() {
	var that = this;

	if(that.toggle) {
		that.toggle.click(async function(e) {
			e.preventDefault();
			if($(this).hasClass('disabled')) {
				return;
			}
			const PK = that.table.find('tr.success').pluck('id');
			let res;

			try {
				res = await ajax({
					action: 'merge',
					data: {model: 'Employers', PK: PK},
				});
			} catch { return; }

			var modal = $($.parseHTML(res.html)).initModal(async function() {
				if(confirm("Are you sure you wish to merge these Employers?  This is irreversible!")) {
					var data = $(this).serializeJSON();
					data.submit = true;
					data.PK = PK;

					const page = that.api.page();

					try {
						await ajax({
							action: 'merge',
							data,
						});
						modal.forceHide();
						that.api.page(page).draw('page');
					} catch { false; }
				}
			});
		});
	}

	employerLookup.bind({
		dialog: $('#employers-dialog'),
		content: $('#employers-dialog-fields, #employers-dialog .modal-footer'),
		input: $('#employers-dialog [name="name"]'),
	});
};

export default Employers;
