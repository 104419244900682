import $ from '../jquery.js';
import ajax from '../ajax.js';

//Activate dropdowns
$('.dropdown-toggle').dropdown();

//Alternate view activation
$('.alt-view').click(async function(e) {
	e.preventDefault();
	const data = $(this).data();

	try {
		await ajax({
			action: 'alt_view',
			data: {
				[`${data.type}_id`]: data.type_id,
				clear: !!data?.clear,
			},
		});
		window.location.reload();
	} catch { false; }
});

$('#alt-view-revert').click(async function(e) {
	e.preventDefault();

	try {
		await ajax({
			action: 'alt_view',
			data: {clear: true},
		});
		window.location.reload();
	} catch { false; }
});

//Focus the first tabbable element when a dialog opens
$(document).on('shown.bs.modal.focus', '.modal', function() {
	$(this).focusFirst(':not(.close)');
});

//Prevent user from tabbing outside of a dialog
$(document).on('keydown', '.modal.in', function(e) {
	$(this).preventTabOut(e);
});

//Have a popup that prevents modals from closing, and let us override it.
$(document).on('hide.bs.modal.prevent-close', '.modal-protect-close', function(e) {
	if ($(this).data('changed')) {
		const text = $(this).data('closeText');

		if (!confirm(text ? text : "Are you sure you wish to close this dialog?")) {
			e.preventDefault();
		}

		$(this).data('changed', false);
	}
});

$(document).on('change.prevent-close keypress.prevent-close', '.modal-protect-close :input', function() {
	$(this).closest('.modal-protect-close').data('changed', true);
});

//hax for overlapping modals
$.fn.modal.Constructor.prototype.oldshow = $.fn.modal.Constructor.prototype.show;
$.fn.modal.Constructor.prototype.show = function(_relatedTarget) {
	this.oldshow.call(this, _relatedTarget);
	if ($('.modal.in').not(this.$element).length > 0) {
		this.$element.addClass('modal-overlap');
		this.$backdrop.addClass('modal-overlap');
	}
};

$(document)
	.off('hidden.bs.modal', '.modal')
	.on('hidden.bs.modal', '.modal', function() {
		if ($('.modal.in').length === 0) {
			$(document.body).removeClass('modal-open');
		}
	});

const main = $('[role="main"]').filter(':visible').first();

if (main.is('table')) {
	main.find(':tabbable:first').focus();
} else if (main.is('form')) {
	main.find('input:visible:first').focus();
} else {
	main.find("form input:visible, table :tabbable").first().focus();
}
