<template>
	<div class="list-group-item">
		<header class="row row-gutter-sm">
			<div class="col col-sm-10">
				<div class="row row-gutter-sm">
					<div class="col col-sm-5">
						<strong>Employer</strong>
					</div>
					<div class="col col-sm-3">
						<strong>Job Type</strong>
					</div>
					<div class="col col-sm-2">
						<strong>Hours</strong>
					</div>
					<div class="col col-sm-2">
						<strong>Earnings</strong>
					</div>
				</div>
			</div>
			<div class="col col-sm-2">
				<strong>Options</strong>
			</div>
		</header>
	</div>
</template>

<script>
	export default {

	};
</script>