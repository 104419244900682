import $ from '../jquery.js';
import config from '../config.js';
import ajax from '../ajax.js';

var Password = {};

Password.init = function() {
	$('#update-password').submit(async function(e) {
		e.preventDefault();

		try {
			await ajax({
				action: 'forceUpdatePassword',
				data: $(this).serializeJSON(),
			});
			alert('Thank you for updating your password!');
			window.location = config.url.base;
		} catch { false; }
	});
};

export default Password;