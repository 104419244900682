import $ from '../jquery.js';
import config from '../config.js';
import ajax from '../ajax.js';

var Login = {};

Login.init = function() {
	$('#login').submit(async function(e) {
		e.preventDefault();
		try {
			await ajax({
				action: 'login',
				data: $(this).serializeJSON(),
			});
			window.location.reload();
		} catch { false; }
	});

	$("#reset-password-form").submit(async function(e) {
		e.preventDefault();
		var that = $(this);
		try {
			const res = await ajax({
				action: 'forgot_password',
				data: $(this).serializeJSON(),
			});
			alert(res.message);
			that[0].reset();
			$("#reset-password").forceHide();
		} catch { false; }
	});

	if ($("#reset-password-confirm").length > 0) {
		$("#reset-password-confirm").modal();

		$("#reset-password-confirm-form").submit(async function(e) {
			e.preventDefault();
			try {
				const res = await ajax({
					action: 'reset_password',
					data: $(this).serializeJSON(),
				});
				alert(res.message);
				window.location = config.url.base;
			} catch { false; }
		});
	}
};

export default Login;