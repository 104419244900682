import importer from './components/importer.vue';
import fileInput from './components/file-input.vue';
import chooseHeaders from './components/choose-headers.vue';
import match from './components/match.vue';
import reviewData from './components/review-data.vue';
import results from './components/results.vue';
import footer from './components/footer.vue';

export default {
	importer,
	"file-input": fileInput,
	"choose-headers": chooseHeaders,
	"review-data": reviewData,
	results,
	match,
	"import-footer": footer,
};
