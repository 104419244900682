import $ from '../jquery.js';
import Vue from 'vue';
// import moment from 'moment';
// import lodash from 'lodash';
import components from './peopleVerify/components.js';
import agencyView from '../elements/agencyView.js';

const people = window.people;
const agency = window.agency;

const DataEntry = {
	data: {},
	app: null,

	init() {
		agencyView.init();
		
		this.loadComponents();

		if ($('#people-verify').length) {
			this.app = new Vue({
				el: '#people-verify',

				data() {
					return {
						people,
						agency,
					};
				},

				methods: {

				},
			});
		}
	},

	loadComponents() {
		for (const component of Object.keys(components)) {
			Vue.component(component, components[ component ]);
		}
	},
}

export default DataEntry;
