<template>
	<div class="panel panel-default">
		<div class="panel-heading">
			<strong>Match columns from the CSV file to imported values.</strong>
		</div>
		<form class="form-horizontal" @submit="handleSubmit">
			<div class="panel-body">
				<div class="form-group" v-for="value in values">
					<label class="col-sm-3 control-label" :for="'choose-header-' + value.key">
						{{ value.name }}:
					</label>
					<div class="col col-sm-4">
						<select :ref="value.key" :id="'choose-header-' + value.key" :name="value.key" required  class="form-control">
							<option value="">(choose a value)</option>
							<option
								:value="header"
								v-for="header in headers" 
								:selected="getDefaultEntry(value) === header.toLowerCase()">{{ header }}</option>
						</select>
					</div>
				</div>
			</div>
			<import-footer
				@back="$emit('back')"
				@saveDraft="$emit('saveDraft', getData())"></import-footer>
		</form>
	</div>
</template>

<script>
	export default {
		props: [ 'headers', 'settings', 'config' ],

		data() {
			const values = [
				{ name: 'MCI', key: 'mci', default: 'MCI' },
				{ name: 'First name', key: 'first_name', default: 'NAM_FIRST' },
				{ name: 'Last name', key: 'last_name', default: 'NAM_LAST' },
				... this.config.headers,
			];

			return { values };
		},

		computed: {
			defaultHeaders() {
				if (this.settings.headers) {
					return this.settings.headers;
				}
				
				let headers = [];

				for (const header of this.headers) {
					for (const value of this.values) {
						if (
							value.default.toLowerCase() === header.toLowerCase() || 
							header.toLowerCase().includes(value.name.toLowerCase())) {
							headers.push({
								from: header,
								to: value.key,
							});
						}
					}
				}

				return headers;
			},
		},

		methods: {
			getDefaultEntry(value) {
				const search = this.defaultHeaders.find(({ to }) => to.toLowerCase() === value.key.toLowerCase());

				return search ? search.from.toLowerCase() : null;
			},

			getData() {
				const headers = [];

				for (const value of this.values) {
					const { options, selectedIndex } = this.$refs[ value.key ][0];

					headers.push({
						from: options[ selectedIndex ].value,
						to: value.key,
					});
				}

				return headers;
			},

			setData(headers) {
				for (const header of headers) {
					this.$refs[ header.to ][0].value = header.from;
				}
			},

			handleSubmit(e) {
				e.preventDefault();

				const headers = this.getData();

				this.$emit('complete', headers);
			},
		},
	}
</script>
